import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "@shared/services/language.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private languageService: LanguageService
  ) {
  }

  passwordForgot(email: String): Observable<any> {
    return this.http.post(environment.domain +
      '/api/password-forgot' +
      '?language=' + this.languageService.getLanguage(),
      email
    );
  }

  passwordResetHashCheck(hash: String): Observable<any> {
    return this.http.get(environment.domain +
      '/api/password-reset' +
      '?token=' + hash
    );
  }

  passwordReset(hash: String, password: String): Observable<any> {
    return this.http.post(environment.domain +
      '/api/password-reset' +
      '?token=' + hash +
      "&language=" + this.languageService.getLanguage(),
      password
    );
  }

  disableUser(id: number): Observable<any> {
    return this.http.put(environment.domain +
      '/api/user/disable', id
    );
  }

  activateUser(id: number): Observable<any> {
    return this.http.put(environment.domain +
      '/api/user/activate', id
    );
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(environment.domain +
      '/api/user?id=' + id
    );
  }

  addUser(userForm: FormGroup) {
    return this.http.put(environment.domain +
      '/api/user' +
      "?language=" + this.languageService.getLanguage(),
      userForm.get('email').value
    );
  }

  passwordSet(hash: string, password: string) {
    return this.http.post(environment.domain + '/api/password-set' +
    '?token=' + hash +
    "&language=" + this.languageService.getLanguage(),
    password
    );
  }

}
