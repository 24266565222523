import {AfterViewInit, Directive, ElementRef, HostListener, OnDestroy} from '@angular/core';

declare var bootstrap: any;

@Directive({
  selector: '[bTooltip]'
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  private tooltip: any;
  private clicked = false;
  private timeoutId: number;

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    const domElement: HTMLElement = this.elementRef.nativeElement;
    this.tooltip = new bootstrap.Tooltip(domElement, {
      placement: 'left', trigger: 'hover', delay: 200
    });
  }

  @HostListener('click', ['$event']) onClick($event) {
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    $event.stopPropagation();
    this.timeoutId = setTimeout(() => {
      this.tooltip.hide();
      this.timeoutId = undefined;
    }, 3000);
  }

  ngOnDestroy(): void {
    this.tooltip.dispose();
  }
}
