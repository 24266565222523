<app-spinner-loader [text]="'accounts.loading'" *ngIf="!init"></app-spinner-loader>

<ng-container *ngIf="init">
<div class="row justify-content-center mt-3" *ngIf="accounts.length > 0">
  <div class="col-3 text-nowrap">
    <span class="float-end">{{'accounts.total' | translate}} {{amountFormat.format(total, userCurrencyId())}}</span>
  </div>
</div>

<div *ngIf="accounts.length === 0" style="margin-top:40vh" class="container justify-content-center col-md-6">
  <div class="alert alert-info text-center" role="alert" [innerHtml]="'accounts.add-first-account' | translate"></div>
</div>

<div *ngFor="let account of accounts" class="accounts transactions-list-item mt-2"
     (click)="showAccountDetails(account)">
  <div class="row font-weight-bold">
      <span class="col-6">
        {{account.name}}
      </span>
    <div class="col-6">
      <span class="float-end">{{amountFormat.format(account.balance, userCurrencyId())}}</span>
    </div>
  </div>

  <div *ngIf="account.subAccountList.length > 1 || (account.subAccountList.length > 0 && account.subAccountList[0].currencyId !== userCurrencyId())"
    class="row mt-2">
    <div *ngFor="let subAccount of account.subAccountList; let subAccountIndex = index">

      <div class="sub-accounts"
           [class.separator]="subAccountIndex != account.subAccountList.length-1 && account.subAccountList.length > 1">
        <div class="row small">
          <div class="col-7">
            <span>{{ subAccount.name }}</span>
          </div>

          <div class="col-5">
              <div class="float-end">
                <span *ngIf="userCurrencyId() !== subAccount.currencyId && subAccount.balance !== 0" bTooltip
                      data-bs-placement="left" data-bs-toggle="tooltip" data-bs-html="true"
                      [attr.data-bs-title]="'<div>' + amountFormat.format(subAccount.clientCurrencyBalance, userCurrencyId())+'</div><div>' + amountFormat.format(1, subAccount.currencyId)+ ' = '  + amountFormat.format(subAccount.clientCurrencyBalance / subAccount.balance, userCurrencyId()) +'</div>'">
                  <span *ngIf="amountFormat.format(subAccount.clientCurrencyBalance, userCurrencyId())"
                        class="fa-solid fa-right-left small">
                  </span>
                  <span>
                    {{amountFormat.format(subAccount.balance, subAccount.currencyId)}}
                  </span>
                </span>

                <span *ngIf="userCurrencyId() !== subAccount.currencyId && subAccount.balance == 0">
                  <span>
                    {{amountFormat.format(subAccount.balance, userCurrencyId())}}
                  </span>
                </span>

                <span *ngIf="userCurrencyId() === subAccount.currencyId">
                  {{amountFormat.format(subAccount.clientCurrencyBalance, userCurrencyId())}}
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pt-5"></div>
<div class="pt-5"></div>

<a (click)="newAccount()" class="float">
  <i class="fa fa-plus my-float"></i>
</a>
</ng-container>
