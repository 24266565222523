import {TRANSACTION_TYPES} from '../transaction-type/transaction-types.enum';

export class SubTransaction {
  amount: number;
  amountTo: number;
  categoryId: number;
  mainCategoryId: number;
  transactionId: number;
  subTransactionId: number;
  excludeStatistics: boolean;
  constantExpense: boolean;
  transactionName: string;
  transactionDate: Date;
  clientCurrencyAmount: number;
  accountFromId: number;
  accountToId: number;
  transactionType: TRANSACTION_TYPES;

  constructor() {
    this.amountTo = 0;
    this.excludeStatistics = false;
    this.constantExpense = false;
  }
}
