import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Transaction} from './models/transaction';
import {TransactionSearchModel} from './transactions-search/transaction-search.model';
import {createRequestOption} from '@shared/utils/request-params.util';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class TransactionService {

  headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

  constructor(private http: HttpClient) {

  }

  addTransaction(model: Transaction) {
    if (model.subAccountFromId) {
      return this.http.put(environment.domain + '/api/transaction/add', {
        name: model.name,
        accountToId: model.subAccountToId,
        accountFromId: model.subAccountFromId,
        subTransactions: model.subTransactions,
        date: model.date,
        transactionType: model.transactionType,
      }, {headers: this.headers});
    }
  }

  editTransaction(model: Transaction) {
    return this.http.post(environment.domain + '/api/transaction/edit', {
      transactionId: model.transactionId,
      name: model.name,
      accountToId: model.subAccountToId,
      accountFromId: model.subAccountFromId,
      subTransactions: model.subTransactions,
      date: model.date,
      subTransactionsToRemove: model.subTransactionsToRemove,
    }, {headers: this.headers});
  }

  getTransactions(transactionSearch: TransactionSearchModel) {
    const options = createRequestOption(transactionSearch);
    return this.http.get(environment.domain + '/api/transaction/all', { params: options });
  }

  getDetails(id: number) {
    return this.http.get(environment.domain + '/api/transaction/details/' + id);
  }

  remove(id: number) {
    return this.http.delete(environment.domain + '/api/transaction/remove/' + id);
  }
}
