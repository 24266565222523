import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaymentResponseModel} from "../settings/model/payment-response.model";

@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  pay(type: String, provider: String, productId?: String): Observable<PaymentResponseModel> {
    return this.http.post<PaymentResponseModel>(environment.domain + '/api/payment/create', { type: type, provider: provider});
  }

  validateGoogle(paymentId: string, token: string, productId: String) {
    return this.http.post<PaymentResponseModel>(environment.domain + '/api/payment/google-pay/validate', { googlePaymentId: paymentId, googleProductId: productId, token: token});
  }
}
