import { NgModule } from '@angular/core';
import {ComponentsModule} from './components/ComponentsModule';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from "@angular/common";
@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    ComponentsModule,
    TranslateModule
  ],
  declarations: [],
})
export class SharedModule {}
