<div class="d-flex align-items-center">

  <div class="container col-md-8 col-lg-6 col-xl-5 mb-5 mt-5" *ngIf="init">

    <h1 class="text-center fw-bold" *ngIf="success" [translate]="'password-reset.title'"></h1>

    <hr class="divider"/>

    <form class="container" [formGroup]="passwordResetForm" (ngSubmit)="sendForm()">

      <div class="alert alert-info text-center" role="alert" *ngIf="success" [translate]="'password-reset.info'"></div>
      <div class="alert alert-danger" role="alert" *ngIf="error" [innerHTML]="'errors.status.' + error | translate">
      </div>

      <div class="form-group mt-5" *ngIf="!error">
        <label for="password" class="form-label" [translate]="'password-reset.password-label'"></label>
        <input aria-describedby="password" class="form-control" id="password" type="password" formControlName="password">
        <app-input-errors [control]="password" [prefix]="'password-reset.password'"></app-input-errors>
      </div>

      <div class="form-group mt-2" *ngIf="!error">
        <label for="confirmPassword" class="form-label" [translate]="'password-reset.confirm-password-label'"></label>
        <input aria-describedby="confirmPassword" class="form-control" id="confirmPassword" type="password" formControlName="confirmPassword">
        <app-input-errors [control]="confirmPassword" [prefix]="'password-reset.confirmPassword'"></app-input-errors>
      </div>

      <div class="row mt-3 justify-content-center" *ngIf="error && !success">
        <div class="d-grid col-6">
          <button routerLink="/password-forgot" class="btn btn-primary btn-block" [translate]="'password-reset.forgot'"></button>
        </div>
      </div>

      <hr class="divider"/>

      <div class="row mt-3 justify-content-center" *ngIf="!error">
        <div class="d-grid col-6">
          <button type="submit" class="btn btn-primary btn-block" [translate]="'password-reset.submit'"></button>
        </div>
      </div>

      <div class="row mt-3 justify-content-center" *ngIf="error && !success">
        <div class="d-grid col-6">
          <button routerLink="/login" class="btn btn-outline-secondary btn-block" [translate]="'password-reset.login'"></button>
        </div>
      </div>

    </form>

  </div>

</div>
