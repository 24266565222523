import {Component} from "@angular/core";
import {LanguageService} from "@shared/services/language.service";

@Component({
  selector: 'app-language',
  template: `
    <div class="btn-group app-language-switch">
      <button type="button" class="btn btn-link nav-link dropdown-toggle mt-1" data-bs-toggle="dropdown" data-bs-display="static">
        <span class="visually-hidden">Toggle Dropdown</span>
        <span [translate]="'language.' + language"></span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><a *ngFor="let languageItem of languages" class="dropdown-item"
               [ngClass]="{'active' : language === languageItem}" (click)="changeLanguage(languageItem)"
               [translate]="'language.' + languageItem"></a></li>
      </ul>
    </div>`
})
export class LanguageComponent {

  language: string;
  languages: string[];

  constructor(private languageService: LanguageService) {
    this.languages = languageService.getLanguages();
    this.language = languageService.getLanguage();
  }

  changeLanguage(language: string) {
    this.language = language;
    this.languageService.change(language);
  }

}
