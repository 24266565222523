export class StatisticsFiltersModel {
  dateFrom: Date;
  dateTo: Date;
  categoryId: number;
  subAccountId: number;
  dateFromNg: string;
  dateToNg: string;

  constructor() {
    const date = new Date();
    this.dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
    this.dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dateFromNg = this.dateFrom.toLocaleDateString('en-CA');
    this.dateToNg = this.dateTo.toLocaleDateString('en-CA');
  }

}
