<div class="d-flex align-items-center">

  <div class="container col-md-8 col-lg-6 col-xl-5 mb-5 mt-5">

    <h1 class="text-center fw-bold" [translate]="'login.title'"></h1>

    <hr class="divider"/>

    <form class="container" [formGroup]="loginForm" (ngSubmit)="logIn()">

      <div class="alert alert-success" role="alert" *ngIf="success" [translate]="'login.' + success"></div>
      <div class="alert alert-danger" role="alert" *ngIf="error" [translate]="'login.error.' + error"></div>

      <div class="form-group">
        <label for="username" class="form-label" [translate]="'login.username-label'"></label>
        <input aria-describedby="username" class="form-control" id="username" type="email" formControlName="username">
        <app-input-errors [control]="username" [prefix]="'login.username'"></app-input-errors>
      </div>

      <div class="form-group mt-2">
        <label for="password" class="form-label" [translate]="'login.password-label'"></label>
        <input class="form-control" id="password" type="password" formControlName="password">
        <app-input-errors [control]="password" [prefix]="'login.password'"></app-input-errors>
      </div>

      <div class="col-10 mt-3">
        <a [routerLink]="'/password-forgot'" class="link-underline-secondary" [translate]="'login.forgot'"></a>
      </div>

      <div class="row mt-5">
        <div class="col-7 pt-1" style="padding-right: 0">
          <div class="form-check form-switch">
            <input id="rememberMe" class="form-check-input" type="checkbox" role="switch" formControlName="rememberMe">
            <label for="rememberMe" class="form-check-label text-nowrap"  [translate]="'login.rememberMe'"></label>
          </div>
        </div>

        <div class="d-grid col-5">
          <button type="submit" class="btn btn-primary btn-block" [translate]="'login.sign-in'" ></button>
        </div>

      </div>
    </form>

    <hr class="divider"/>

    <div class="row text-center justify-content-center">
      <span class="fw-lighter" [translate]="'login.register-question'"></span>
      <div class="d-grid col-6 mt-2">
        <button routerLink="/register" class="btn btn-outline-secondary" [translate]="'login.register'"></button>
      </div>
    </div>
  </div>
</div>
