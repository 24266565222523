import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-spinner-loader',
  template:
    `<div class="row h-100 flex flex-column" style="margin-top:40vh">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="d-flex justify-content-center" *ngIf="text">
        <span [translate]="text"></span>
      </div>
    </div>`
})
export class SpinnerLoaderComponent {
  @Input() text?;
}
