import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FormGroup} from "@angular/forms";
import {environment} from "../../environments/environment";
import {LocalStorageService} from "@shared/services/local-storage.service";
import {LANGUAGE_KEY} from "@shared/services/language.service";
import {DEFAULT_LANGUAGE} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
  }

  logIn(formGroup: FormGroup) {
    return this.http.post(environment.domain + '/auth/login', {
      email: formGroup.get('username').value,
      password: formGroup.get('password').value,
      rememberMe: formGroup.get('rememberMe').value
    });
  }

  logOut(): Observable<any> {
    return this.http.post(environment.domain + '/api/logout', {});
  }

  register(register: any) {
    return this.http.put(environment.domain + '/api/register?language=' + this.localStorageService.getItem(LANGUAGE_KEY, DEFAULT_LANGUAGE), register);
  }

  getAccount() {
    return this.http.get(environment.domain + '/api/user/account');
  }

}
