import {Component} from "@angular/core";
import {ThemeService} from "@shared/services/theme.service";

@Component({
  selector: 'app-theme',
  template: `
    <div class="form-check form-switch app-theme-switch">
      <label *ngIf="!darkTheme" for="darkMode" class="form-check-label fa-solid fa-sun"></label>
      <label *ngIf="darkTheme" for="darkMode" class="form-check-label fa-solid fa-moon"></label>
      <input [name]="'theme'" [(ngModel)]="darkTheme" (ngModelChange)="changeTheme()" id="darkMode1"
             class="form-check-input" type="checkbox" role="switch">
    </div>`
})
export class ThemeComponent {

  darkTheme: boolean;

  constructor(private themeService: ThemeService) {
    this.darkTheme = themeService.isDark();
  }

  changeTheme() {
    this.themeService.change(this.darkTheme);
  }

}
