import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export function matchValidator(name1: string, name2: string): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const control1 = group.controls[name1];
    const control2 = group.controls[name2];
    if (control1.value !== control2.value && control1.value !== '' && control2.value !== '') {
      control2.setErrors({ nomatch: true});
    } else if (control1.value === control2.value && control1.value !== '' && control2.value !== '') {
      control2.setErrors(null);
    }
    return;
  };
}
