import {Component, OnInit} from '@angular/core';
import {LoginService} from '../login/login.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {matchValidator} from './matchValidator';

@Component({
  selector: 'app-register', templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  error: string;
  init: boolean;

  get mail() {
    return this.registerForm.get('mail');
  }

  get password() {
    return this.registerForm.get('password');
  }

  get confirmPassword() {
    return this.registerForm.get('confirmPassword');
  }

  get acceptTerms() {
    return this.registerForm.get('acceptTerms');
  }

  constructor(private router: Router, private loginService: LoginService) {
  }

  ngOnInit(): void {

    this.error = undefined;

    this.registerForm = new FormGroup({
      mail: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      acceptTerms: new FormControl(false, [Validators.requiredTrue])
    }, {
      validators: [matchValidator('password', 'confirmPassword')]
    });

    this.init = true;
  }

  get f() {
    return this.registerForm.controls;
  }

  register() {
    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid) {
      this.loginService.register(this.registerForm.value)
        .subscribe(
          () => this.router.navigate(["/login"], {queryParams: {registration: "success"}}),
          (error) => {
            this.error = error.name;
          }
          );
    } else {
      return;
    }
  }

}


