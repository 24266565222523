import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

export const categoriesRoute = 'categories';
export const categoryRoute = 'category';

@Injectable({
  providedIn: 'root',
})
export class CategoriesRoute {

  constructor(private router: Router) {
  }

  categories() {
    this.router.navigate([categoriesRoute]).then(() => null);
  }

  newCategory() {
    this.router.navigate([categoryRoute]).then(() => null);
  }

  categoryDetails(id: number) {
    this.router.navigate([categoryRoute], {queryParams: {id: id}}).then(() => null);
  }

}
