import {NgModule} from "@angular/core";
import {SharedModule} from "@shared/SharedModule";
import {RouterModule, Routes} from "@angular/router";
import {SettingsComponent} from "./settings.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserComponent} from "./user/user.component";

const routes: Routes = [
  { path: 'settings', component: SettingsComponent },
  { path: 'settings/user/add', component: UserComponent }
];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    SettingsComponent,
    UserComponent
  ]
})
export class SettingsModule { }
