import {Component, OnInit} from '@angular/core';
import {StatisticsService} from '../statistics.service';
import {Category} from '../../category/models/category';
import {SubTransaction} from '../../transaction/models/sub-transaction';
import {Router} from '@angular/router';
import {SubAccount} from '../../account/models/sub-account';
import {TRANSACTION_TYPES} from '../../transaction/transaction-type/transaction-types.enum';
import {SubCategory} from '../../category/models/sub-category.model';
import {DataService} from '@shared/DataService';
import {StatisticsFiltersModel} from '../model/statistics-filters.model';
import {NumberFormatService} from "@shared/services/number-format.service";
import {AuthenticationService} from "../../configuration/authentication.service";
import {
  ChartConfiguration,
  Chart
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as pluginDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

@Component({
  templateUrl: './statistics.html'
})
export class StatisticsComponent implements OnInit {

  public barChartLegend = false;

  public barChartData: ChartConfiguration<'bar'>['data'];
  public barChartPlugins = [pluginDataLabels];

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    indexAxis: "y",
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false
      },
      y: {
        ticks: {
          crossAlign: "far",
        }
      },
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'start',
        align: 'left',
        clamp: true,
        backgroundColor: 'white',
        borderRadius: 3,
        color: 'black',
        font: {
          weight: 'bold'
        }
      },
    }
  };

  TransactionTypes = TRANSACTION_TYPES;
  loading = true;
  statistics = [];
  accounts: SubAccount[] = [];
  categories: Category[];
  subCategories: SubCategory[];
  transactions: SubTransaction[];
  transactionsFiltered: SubTransaction[];
  backgroundColor = [];
  filters: StatisticsFiltersModel;
  income: number;
  outcome: number;
  sum: number;

  public pieChartLabels: string[] = [];
  public pieChartData: number[] = [];
  init: boolean;


  constructor(private statisticsService: StatisticsService,
              private dataService: DataService,
              private router: Router,
              public amountService: NumberFormatService,
              protected userService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.dataService.getMainCategories().then(mainCategories => {
      this.categories = mainCategories as Category[];
      this.dataService.getSubCategories().then(data => {
        this.subCategories = data as SubCategory[];
        this.dataService.getSubAccounts().then(accounts => {
          this.accounts = accounts;
          this.filters = this.dataService.getStatisticsFilters(false);
          this.getCategories();
          this.init = true;
        });
      });
    });
  }

  getSubCategoryNameById(id: number): string {
    return this.subCategories.find(value => value.id === id).name;
  }

  details(id: number) {
    this.router.navigate(['transactions/edit'], { queryParams: { id: id } });
  }

  getCategories() {
    this.loading = true;
    const dataStatistics = {};
    setTimeout(() => {
      this.statisticsService.getTransactions(this.filters).subscribe(tr => {
        this.transactions = tr as SubTransaction[];
        this.income = 0;
        this.outcome = 0;
        this.transactionsFiltered = this.transactions.filter(value => !value.excludeStatistics);
        for (const subTransaction of this.transactionsFiltered) {
          const categoryId = this.filters.categoryId ? subTransaction.categoryId : subTransaction.mainCategoryId;
          if (dataStatistics[categoryId] === undefined) {
            dataStatistics[categoryId] = {
              amount: 0,
              categoryId: categoryId
            };
          }

          let subTransactionAmount = subTransaction.clientCurrencyAmount;

          if (subTransaction.transactionType === TRANSACTION_TYPES.INCOME) {
            if (!subTransaction.constantExpense) {
              this.income = this.income + subTransactionAmount;
              dataStatistics[categoryId].amount = (parseFloat(dataStatistics[categoryId].amount) + subTransactionAmount).toFixed(2);
            }
          } else if (subTransaction.transactionType === TRANSACTION_TYPES.EXPENSE) {
            if (!subTransaction.constantExpense) {
              this.outcome = this.outcome - subTransactionAmount;
              dataStatistics[categoryId].amount = (parseFloat(dataStatistics[categoryId].amount) - subTransactionAmount).toFixed(2);
            } else {
              this.income = this.income - subTransactionAmount;
              dataStatistics[categoryId].amount = (parseFloat(dataStatistics[categoryId].amount) - subTransactionAmount).toFixed(2);
            }
          }

        }

        this.sum = this.income + this.outcome;

        this.pieChartLabels = [];
        this.pieChartData = [];
        this.barChartData = undefined;
        this.backgroundColor.length = 0;
        this.pieChartData.length = 0;
        this.backgroundColor.length = 0;

        const sortable = [];

        for (const key of Object.keys(dataStatistics)) {
          sortable.push(dataStatistics[key]);
        }

        sortable.sort(function(a, b) {
          return b.amount - a.amount;
        });

        for (const statistic of sortable) {
          let category;
          if (this.filters.categoryId) {
            category = this.subCategories.find(value => value.id === statistic.categoryId);
          } else {
            category = this.categories.find(value => value.id === statistic.categoryId);
          }
          this.pieChartLabels.push(category.name);
          this.pieChartData.push(statistic.amount);
          this.backgroundColor.push(category.color);
        }

        this.barChartData = {
            labels: this.pieChartLabels,
            datasets: [
              { data: this.pieChartData, backgroundColor: this.backgroundColor }
            ]
          };

      }).add(() => {
        this.loading = false;
      });
    });
  }

  dateFromChange() {
    this.filters.dateFrom = new Date(this.filters.dateFromNg);
    this.getCategories();
  }

  dateToChange() {
    this.filters.dateTo = new Date(this.filters.dateToNg);
    this.getCategories();
  }

  changeMonth(number: number) {
    const offset = this.filters.dateFrom.getMonth() + number;
    const year = this.filters.dateFrom.getFullYear();
    this.filters.dateFrom = new Date(year, offset, 1);
    this.filters.dateTo = new Date(year, offset + 1, 0);
    this.filters.dateFromNg = this.filters.dateFrom.toLocaleDateString('en-CA');
    this.filters.dateToNg = this.filters.dateTo.toLocaleDateString('en-CA');
    this.getCategories();
  }

  clearFilters() {
    this.filters = this.dataService.getStatisticsFilters(true);
    this.getCategories();
  }

  getChartStyle() {
    let height = (5 * this.pieChartLabels.length) + 3
    return 'min-height:' + height + 'vh; max-height: ' + height + 'vh; width:auto';
  }

  isExpense(type: TRANSACTION_TYPES) {
    return type === TRANSACTION_TYPES.EXPENSE;
  }

  getTransactionAmount(amount: number, type: TRANSACTION_TYPES) {
    if (this.isExpense(type)) {
      amount = amount * -1;
    }
    return this.amountService.format(amount, this.userService.userCurrencyId());
  }

  getTransactionTypeClass(type: TRANSACTION_TYPES) {
    switch (type) {
      case this.TransactionTypes.EXPENSE:
        return 'text-danger';
      case this.TransactionTypes.INCOME:
        return 'text-success';
      case this.TransactionTypes.OWN_TRANSFER:
        return 'text-secondary';
    }
  }

  getTransactionCurrencyTooltip(subTransaction: SubTransaction, amount: number, clientCurrencyAmount: number) {
    return '<div>' + this.getSubTransactionClientAmount(subTransaction, amount, clientCurrencyAmount) + '</div>' +
      '<div>' + this.amountService.format(1, this.getAccountCurrency(subTransaction.accountFromId))+ ' = '  + this.amountService.format(clientCurrencyAmount / amount, this.userService.getUser().currencyId) + '</div>' +
      '<div>' + this.amountService.format(1, this.userService.getUser().currencyId)+ ' = '  + this.amountService.format(amount / clientCurrencyAmount, this.getAccountCurrency(subTransaction.accountFromId)) + '</div>';
  }

  getSubTransactionClientAmount(subTransaction: SubTransaction, amount: number, clientCurrencyAmount: number) {
    let amountValue;
    if(clientCurrencyAmount) {
      amountValue = clientCurrencyAmount;
    } else {
      amountValue = amount;
    }
    if (this.isExpense(subTransaction.transactionType)) {
      amountValue = amountValue * -1;
    }
    return this.amountService.format(amountValue, this.userService.getUser().currencyId);
  }

  getAccountCurrency(accountId: number): number {
    let id = this.accounts.find(value => value.id === accountId).currencyId;
    return this.userService.getUser().currencies.find(value => value.id === id).id;
  }

  getSubTransactionAmount(subTransaction: SubTransaction) {
    let amount = subTransaction.amount;
    if (subTransaction.transactionType === TRANSACTION_TYPES.EXPENSE) {
      amount = amount * -1;
    }
    return this.amountService.format(amount, this.getAccountCurrency(subTransaction.accountFromId));
  }

}
