<div class="container" *ngIf="init">

  <nav *ngIf="userService.isAuthenticated()" class="navbar navbar-expand-lg bg-body-tertiary">
    <button class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasResponsive"
            aria-controls="offcanvasResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="offcanvas-lg offcanvas-start col-7" tabindex="-1" id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasResponsiveLabel">MoneyGuardian</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body col-auto">
        <ul class="navbar-nav mt-lg-0" style="width: 100%">
          <li class="nav-item mr-3" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
            <a class="nav-link" [routerLink]="['/transactions']" [routerLinkActive]="['active']" [translate]="'navbar.transactions'"></a>
          </li>
          <li class="nav-item mr-3" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
            <a class="nav-link" [routerLink]="['/accounts']" [routerLinkActive]="['active']" [translate]="'navbar.accounts'"></a>
          </li>
          <li class="nav-item mr-3" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
            <a class="nav-link" [routerLink]="['/categories']" [routerLinkActive]="['active']" [translate]="'navbar.categories'"></a>
          </li>
          <li class="nav-item mr-3" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
            <a [routerLink]="['/statistics']" class="nav-link" [routerLinkActive]="['active']" [translate]="'navbar.statistics'"></a>
          </li>
          <li class="nav-item mr-3" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
            <a [routerLink]="['/settings']" class="nav-link" [routerLinkActive]="['active']" [translate]="'navbar.settings'"></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="d-flex justify-content-end column-gap-3 col-5">
      <app-language class="mt-1"></app-language>
      <app-theme class="mt-1"></app-theme>
      <button *ngIf="userService.isAuthenticated()" (click)="logOut()" class="btn btn-outline-secondary text-nowrap" [translate]="'navbar.signOff'"></button>
    </div>

  </nav>

  <div class="row justify-content-center" *ngIf="!userService.isAuthenticated()">

    <div class="row justify-content-center col-md-7 col-lg-6 col-xl-5">

      <div class="col-8">
        <img ngSrc="assets/MoneyGuardianLogo.png" [height]="51" [width]="235" class="m-1">
      </div>

      <div class="col-4">
        <div class="d-flex column-gap-3 justify-content-end pt-3">
          <app-language></app-language>
          <app-theme></app-theme>
        </div>
      </div>
    </div>
  </div>

</div>
