export class ModalsModel {
  title: string;
  body: string;
  cancel: string;
  confirm: string;
  promise: Promise<any>;
  promiseResolve: (value: any) => void;
  promiseReject: () => void;
  canClose: boolean = true;
  visible: boolean = true;

  constructor(modal: Partial<ModalsModel>) {
    this.title = modal.title;
    this.body = modal.body;
    const self = this;
    this.promise = new Promise<any>((resolve, reject) => {
      self.promiseResolve = resolve;
      self.promiseReject = reject;
    });
    if(modal.cancel) {
      this.cancel = modal.cancel;
    }
    if(modal.confirm) {
      this.confirm = modal.confirm;
    }
    if(modal.canClose) {
      this.canClose = modal.canClose;
    }
  }

}
