import { Injectable } from '@angular/core';
import {Category} from '../app/category/models/category';
import {CategoryDataService} from '../app/category/categoryDataService';
import {SubCategory} from '../app/category/models/sub-category.model';
import {AccountDataService} from '../app/account/accountDataService';
import {SubAccount} from '../app/account/models/sub-account';
import {Account} from '../app/account/models/account';
import {StatisticsFiltersModel} from '../app/statistics/model/statistics-filters.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  filters: StatisticsFiltersModel = new StatisticsFiltersModel();

  constructor(
    private categoryDataService: CategoryDataService,
    private accountDataService: AccountDataService
  ) {}

  getSubCategories(force = false): Promise<SubCategory[]> {
    return this.categoryDataService.getSubCategories(force);
  }

  getMainCategories(force = false): Promise<Category[]> {
    return this.categoryDataService.getMainCategories(force);
  }

  getAccounts(force = false): Promise<Account[]> {
    return this.accountDataService.getAccounts(force);
  }

  getSubAccounts(force = false): Promise<SubAccount[]> {
    return this.accountDataService.getSubAccounts(force);
  }

  getStatisticsFilters(clear: boolean): StatisticsFiltersModel {
    if (clear) {
      this.filters = new StatisticsFiltersModel();
    }
    return this.filters;
  }

  clearAccounts() {
    this.accountDataService.clear();
  }

  clear() {
    this.accountDataService.clear();
    this.categoryDataService.clear();
  }
}
