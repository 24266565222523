import {Component} from "@angular/core";
import {ModalService} from "@shared/services/modal.service";

@Component({
  templateUrl: "modals.template.html",
  selector: "budget-modals"
})
export class ModalsComponent {

  constructor(public modalService: ModalService) {

  }

}
