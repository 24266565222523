import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {RouterModule, Routes} from '@angular/router';

// import the new component
import {CategoryComponent} from './components/details/category.component';
import {CategoriesComponent} from './components/list/categories.component';
import {SharedModule} from "@shared/SharedModule";
import {ColorPickerModule} from "ngx-color-picker";
import {categoryRoute, categoriesRoute} from "./categories.route";

const routes: Routes = [
  {path: categoryRoute, component: CategoryComponent},
  {path: categoriesRoute, component: CategoriesComponent}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    SharedModule,
    ColorPickerModule
  ],
  exports: [RouterModule],
  declarations: [
    CategoryComponent,
    CategoriesComponent
  ],
})
export class CategoryModule {
}
