import {Component} from '@angular/core';
import {AccountService} from '../../account.service';
import {Router} from '@angular/router';
import {AuthenticationService} from "../../../configuration/authentication.service";
import {Account} from "../../models/account";
import {DataService} from "@shared/DataService";
import {NumberFormatService} from "@shared/services/number-format.service";
import {AccountsRoute} from "../../accounts.route";

@Component({
  templateUrl: 'accounts.html'
})
export class AccountsComponent {

  accounts: Account[] = [];
  total = 0;
  init: boolean;

  constructor(private accountService: AccountService,
              private userService: AuthenticationService,
              private router: Router,
              private dataService: DataService,
              public amountFormat: NumberFormatService,
              private accountRoute: AccountsRoute) {
  }

  ngOnInit(): void { // adding the lifecycle hook ngOnInit
    this.init = false;
    this.getAccounts();
  }

  getAccounts() {
    this.dataService.getAccounts(true).then(accounts => {
      this.accounts = accounts;
      this.getSum();
      this.init = true;
    });
  }

  getSum() {
    this.total = this.accounts.reduce(function (acc, obj) {
      return acc + obj.balance;
    }, 0);
  }

  userCurrencyId(): number {
    return this.userService.userCurrencyId();
  }

  showAccountDetails(account: Account) {
    this.accountRoute.accountDetails(account.id);
  }

  newAccount() {
    this.accountRoute.newAccount();
  }
}
