import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-input-errors',
  templateUrl: './input-error.html',
  host: {'class': 'invalid-feedback d-block'}
})
export class InputErrorsComponent {

  @Input() control: AbstractControl;
  @Input() prefix: String;

  constructor() { }

  protected readonly Object = Object;
}
