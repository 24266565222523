import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getSettings(): Observable<any> {
    return this.http.get(environment.domain + '/api/settings');
  }

  changeCurrency(currencyId: number) {
    return this.http.post(environment.domain + '/api/settings/currency', currencyId);
  }
}
