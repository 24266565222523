<div class="mt-3" *ngIf="init">

  <div class="col-12">
    <div class="form-group">
      <label for="currency" class="form-label" [translate]="'settings.currency'"></label>
      <select id="currency" name="currency" class="form-select" required [(ngModel)]="settings.currencyId" (ngModelChange)="changeCurrency(settings.currencyId)">
        <option *ngFor="let currency of getCurrencies()"
                [ngValue]="currency.id" [translate]="'currency.' + currency.code"></option>
      </select>
    </div>
  </div>

  <div class="transactions-list-day text-start">
    <span [translate]="'settings.users'"></span>
  </div>

  <div [class.loading]="loading" disabled="{{loading}}">
    <div class="list-item click mt-1" *ngFor="let user of addedUsers(settings.userDetails)" (click)="selectUser(user)">
      <div class="row flex-nowrap">
        <span>{{user.email}}</span>
      </div>

      <div class="row flex-nowrap">
        <span class="small text-success" *ngIf="user.active" [translate]="'settings.user.active'"></span>
        <span class="small text-warning" *ngIf="!user.active && !user.activatedAt" [translate]="'settings.user.notActive'"></span>
        <span class="small text-danger" *ngIf="!user.active && user.activatedAt" [translate]="'settings.user.blocked'"></span>
      </div>

      <div *ngIf="user.selected" >

        <div class="row flex-nowrap">

        </div>

        <div class="row mt-3">
          <div class="d-grid gap-2 d-flex justify-content-end">
            <button class="btn btn-outline-warning btn-sm" type="button" *ngIf="!user.owner && user.active" [translate]="'settings.user.disable'" click-stop-propagation (click)="disableUser(user)"></button>
            <button class="btn btn-outline-warning btn-sm" type="button" *ngIf="!user.owner && !user.active && user.activatedAt" [translate]="'settings.user.activate'" click-stop-propagation (click)="activateUser(user)"></button>
            <button class="btn btn-outline-danger btn-sm" type="button" [translate]="'settings.user.delete'" click-stop-propagation (click)="deleteUser(user)"></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="d-grid gap-2 d-flex justify-content-end">
      <button class="btn btn-outline-primary" type="button" [translate]="'settings.user.add'" click-stop-propagation (click)="addUser()"></button>
    </div>
  </div>

<!--  <div class="row mt-5">-->

<!--    <div>-->
<!--      <h1 class="text-center" [translate]="'settings.payment.header'"></h1>-->
<!--    </div>-->

<!--    <ng-container *ngIf="!settings.subscriptionPlan">-->

<!--      <ng-container *ngIf="environment.isAndroid">-->
<!--        <div *ngFor="let product of products" class="d-grid gap-2 col-6 mx-auto">-->
<!--          {{product.id}} {{product.description}} {{product.pricing.price}} {{product.pricing.currency}}-->
<!--          <button class="btn btn-primary" (click)="requestPayment(product)">{{product.id}}</button>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <ng-container *ngIf="!environment.isAndroid">-->
<!--        <div class="d-grid gap-2 col-6 mx-auto">-->
<!--          <button class="btn btn-primary" (click)="paypal()">PAYPAL</button>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="settings.subscriptionPlan">-->
<!--      {{settings.subscriptionPlan.subscriptionType}}-->
<!--      {{settings.subscriptionPlan.startDate | date:'medium' }}-->
<!--      {{settings.subscriptionPlan.endDate | date:'medium' }}-->
<!--    </ng-container>-->
<!--  </div>-->

</div>
