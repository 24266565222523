import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../category.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Category} from '../../models/category';
import {SubCategory} from '../../models/sub-category.model';
import {DataService} from "@shared/DataService";
import {NgForm} from "@angular/forms";
import {CategoryDataService} from "../../categoryDataService";
import {CategoriesRoute} from "../../categories.route";
import {ModalService} from "@shared/services/modal.service";

@Component({
  templateUrl: './category.html'
})
export class CategoryComponent implements OnInit {
  category = new Category();
  init: boolean;

  constructor(
    private categoryService: CategoryService,
    private categoryDataService: CategoryDataService,
    private router: Router,
    private dataService: DataService,
    private route: ActivatedRoute,
    private categoriesRoute: CategoriesRoute,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.init = false;
    this.route.queryParams.subscribe((params: Params) => {
      const id = params['id'];
      if(id) {
        this.categoryService.getCategory(id).subscribe(data => {
          this.category = data as Category;
          this.init = true;
        });
      } else {
        this.category.subCategories.push(new SubCategory());
        this.init = true;
      }
    });
  }

  onSubmit(form: NgForm) {
    if(form.form.valid) {
      if(!this.category.id) {
        const modal = this.showModal('categories.adding-category-modal');
        this.categoryService.addCategory(this.category).subscribe(() => {
          this.categoryDataService.clear();
          this.categoriesRoute.categories();
          this.modalService.close(modal);
        });
      } else {
        const modal = this.showModal('categories.editing-category-modal');
        this.categoryService.editCategory(this.category).subscribe(() => {
          this.categoryDataService.clear();
          this.categoriesRoute.categories();
          this.modalService.close(modal);
        });
      }
    } else {
      form.form.markAllAsTouched();
    }
  }

  showModal(key: string) {
    return this.modalService.waitModal(key);
  }

  addSubCategory(): void {
    this.category.subCategories.push(new SubCategory());
  }

  removeSubCategory(subCategory): void {
    if(subCategory.id) {
      this.category.toRemove.push(subCategory.id);
    }
    this.category.subCategories.splice(this.category.subCategories.indexOf(subCategory), 1);
  }
}
