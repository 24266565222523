import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login.component';
import {ComponentsModule} from "@shared/components/ComponentsModule";
import {SharedModule} from "@shared/SharedModule";
import {authGuard} from "../configuration/auth.guard";

const routes: Routes = [
  { path: 'login',
    component: LoginComponent,
    canActivate: [authGuard]
  }
];


@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule
  ],
  exports: [RouterModule, LoginComponent],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule {

}
