<div class="d-flex align-items-center">

  <div class="container col-md-8 col-lg-6 col-xl-5 mb-5 mt-5">

    <h1 class="text-center fw-bold" [translate]="'register.title'"></h1>

    <hr class="divider"/>

    <form class="container"  [formGroup]="registerForm" (ngSubmit)="register()" *ngIf="init">

      <div class="alert alert-danger" role="alert" *ngIf="error" [translate]="'errors.status.' + error"></div>

      <div class="form-group">
        <label for="mail" class="form-label" [translate]="'register.email.label'"></label>
        <input formControlName="mail" aria-describedby="emailHelp" class="form-control" id="mail" type="email">
        <app-input-errors [control]="mail" [prefix]="'register.email'"></app-input-errors>
      </div>

      <div class="form-group mt-2">
        <label for="password" class="form-label" [translate]="'register.password.label'"></label>
        <input formControlName="password" class="form-control" id="password" type="password">
        <app-input-errors [control]="password" [prefix]="'register.password'"></app-input-errors>
      </div>

      <div class="form-group mt-2">
        <label for="confirmPassword" class="form-label" [translate]="'register.confirmPassword.label'"></label>
        <input formControlName="confirmPassword" class="form-control" id="confirmPassword" type="password">
        <app-input-errors [control]="confirmPassword" [prefix]="'register.confirmPassword'"></app-input-errors>
      </div>

      <div class="form-check form-switch mt-2">
        <label for="acceptTerms" class="form-check-label" [translate]="'register.acceptTerms.label'"></label>
        <input id="acceptTerms" class="form-check-input" type="checkbox" role="switch" formControlName="acceptTerms">
        <app-input-errors [control]="acceptTerms" [prefix]="'register.acceptTerms'"></app-input-errors>
      </div>

      <hr class="divider"/>

      <div class="row mt-3 justify-content-center">
        <div class="d-grid col-6">
          <button type="submit" class="btn btn-primary btn-block" [translate]="'register.register-button'"></button>
        </div>
      </div>

      <div class="row mt-3">
        <span class="text-center">
        <span [translate]="'register.log-in-question'"></span><a [routerLink]="['/login']" class="link-underline-secondary" [translate]="'register.log-in'"></a>
        </span>
      </div>

    </form>
  </div>
</div>
