import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {RouterModule, Routes} from '@angular/router';
import {StatisticsComponent} from './list/statistics.component';
import {NgChartsModule} from 'ng2-charts';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from "@shared/SharedModule";

const routes: Routes = [
  { path: 'statistics', component: StatisticsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgChartsModule,
    SharedModule,
    NgbDatepickerModule
  ],
  exports: [RouterModule],
  declarations: [
    StatisticsComponent,
  ],
})
export class StatisticsModule { }
