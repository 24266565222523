import {inject} from '@angular/core';
import { Router } from '@angular/router';
import {AuthenticationService} from "./authentication.service";


export const authGuard = () => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  console.log("authGuard");

  if (!authService.isAuthenticated()) {
    return true;
  }

  // Redirect to the login page
  return router.parseUrl('/transactions');
};
