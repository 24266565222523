import {Component} from '@angular/core';
import {AuthenticationService} from '../configuration/authentication.service';
import {Router} from '@angular/router';
import {LoginService} from '../login/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.html'
})
export class NavbarComponent {
  login: string;
  password: string;
  init: boolean;

  constructor(
    private router: Router,
    public userService: AuthenticationService,
    private loginService: LoginService) {

    this.init = true;

  }

  logOut() {
    this.loginService.logOut().subscribe(() => {
      this.userService.logout();
      this.router.navigateByUrl('/login');
    });
  }
}
