import {SubTransaction} from './sub-transaction';
import {TRANSACTION_TYPES} from '../transaction-type/transaction-types.enum';

export class Transaction {
  subTransactions: SubTransaction[] = [];
  name: string;
  subAccountFromId: number;
  subAccountToId: number;
  amount: number;
  amountTo: number;
  transactionId: number;
  transactionType: TRANSACTION_TYPES = TRANSACTION_TYPES.EXPENSE;
  subTransactionsToRemove: number[] = [];
  show = false;
  statistics = false;
  amountInClientCurrency: number;
  date: string = new Date().toLocaleDateString('en-CA');

  constructor() {
  }
}
