import {Component, OnInit} from "@angular/core";
import {UserService} from "../user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {matchValidator} from "../../register/matchValidator";
import {ErrorData} from "@shared/misc/error-data";

@Component({
  templateUrl: "./password-reset.html"
})
export class PasswordResetComponent implements OnInit {
  passwordResetForm: FormGroup;
  success: boolean;
  error: ErrorData;
  init: boolean;
  private token: string;

  get password() {
    return this.passwordResetForm.get('password');
  }

  get confirmPassword() {
    return this.passwordResetForm.get('confirmPassword');
  }

  get f() {
    return this.passwordResetForm.controls;
  }

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.success = false;
    this.error = undefined;

    this.passwordResetForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    }, {
      validators: [matchValidator('password', 'confirmPassword')]
    });

    this.route.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
      this.userService.passwordResetHashCheck(this.token).subscribe(
        () => {
          this.success = true;
      }, error1 => {
          this.error = error1.name;
      }).add(() => {
        this.init = true;
      });
    });
  }

  sendForm() {
    this.passwordResetForm.markAllAsTouched();
    if (this.passwordResetForm.valid) {
      this.userService.passwordReset(this.token, this.password.value)
        .subscribe(() => this.router.navigate(["/login"], {queryParams: {passwordReset: "success"}}), (error) => {
          this.error = error.error;
        });
    } else {
      return;
    }
  }
}
