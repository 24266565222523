import {Injectable} from '@angular/core';
import {AccountService} from './account.service';
import {SubAccount} from './models/sub-account';
import {Account} from './models/account';

@Injectable()
export class AccountDataService {

  accounts: Account[];

  constructor(private accountService: AccountService) {}

  getSubAccounts(force = false) {
    return new Promise<SubAccount[]>((resolve) => {
      if (this.accounts != null && !force) {
        resolve(this.collectSubAccounts());
      } else {
        this.accountService.getAccounts().subscribe((data) => {
          this.accounts = data as Account[];
          resolve(this.collectSubAccounts());
        });
      }
    });
  }

  getAccounts(force: boolean) {
    return new Promise<Account[]>((resolve) => {
      if (this.accounts != null && !force) {
        resolve(this.accounts);
      } else {
        this.accountService.getAccounts().subscribe((data) => {
          this.accounts = data as Account[];
          resolve(this.accounts);
        });
      }
    });
  }

  collectSubAccounts(): SubAccount[] {
    let subAccounts: SubAccount[] = [];

    for(let i=0; i< this.accounts.length; i++) {
      subAccounts = subAccounts.concat(this.accounts[i].subAccountList);
    }
    return subAccounts;
  }


  clear() {
    this.accounts = null;
  }
}
