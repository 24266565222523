import {Component, OnInit} from "@angular/core";
import {DataService} from "@shared/DataService";
import {AuthenticationService} from "../configuration/authentication.service";
import {SettingsService} from "./settings.service";
import {SettingsModel} from "./settings.model";
import {CURRENCY_TYPE} from "../currency/models/CurrencyType";
import {UserDetails} from "../configuration/UserDetails";
import {Subscription} from "rxjs";
import {UserService} from "../user/user.service";
import {Router} from "@angular/router";
import 'cordova-plugin-purchase';
import {environment} from "../../environments/environment";
import {PaymentService} from "../payment/payment.service";
import Product = CdvPurchase.Product;
import Platform = CdvPurchase.Platform;


@Component({
  templateUrl: 'settings.component.html'
})
export class SettingsComponent implements OnInit {
  init: boolean;
  settings: SettingsModel;
  loading: boolean;
  products: Product[] = [];

  constructor(private dataService: DataService,
              private authService: AuthenticationService,
              private userService: UserService,
              private paymentService: PaymentService,
              private router: Router,
              private settingsService: SettingsService) {
    CdvPurchase.store.when().approved(this.finishPurchase.bind(this));
  }

  ngOnInit(): void {

    this.init = false;
    this.loading = true;

    this.getSettings().add(() => {
      this.init = true;
      this.products = CdvPurchase.store.products;
    });
  }

  requestPayment(product: Product) {
    if(product.platform === Platform.GOOGLE_PLAY) {
      this.paymentService.pay("BASIC", "ANDROID_GPAY", product.id).subscribe(value => {
        CdvPurchase.store.order(product.getOffer(), {applicationUsername: value.paymentId}).then(() => {});
      });
    }
  }

  finishPurchase(p) {
    if (p.platform === Platform.GOOGLE_PLAY) {
      this.init = false;
      this.paymentService.validateGoogle(p.nativePurchase.accountId, p.nativePurchase.purchaseToken, p.nativePurchase.productIds[0]).subscribe(value => {
        this.getSettings().add(() => {
          this.init = true;
        });
      });
    }
  }

  getCurrencies() {
    return this.authService.getUser().currencies.filter(value => {
      return value.type === CURRENCY_TYPE.CRYPTO || CURRENCY_TYPE.CASH === value.type
    })
  }

  changeCurrency(currencyId: number) {
    this.settingsService.changeCurrency(currencyId).subscribe(() => {
      this.authService.getUser().currencyId = currencyId;
      this.dataService.clearAccounts();
    });
  }

  disableUser(user: UserDetails) {
    this.loading = true;
    this.userService.disableUser(user.id).subscribe(() => {
      this.getSettings();
    });
  }

  activateUser(user: UserDetails) {
    this.loading = true;
    this.userService.activateUser(user.id).subscribe(() => {
      this.getSettings();
    });
  }

  getSettings(): Subscription {
    return this.settingsService.getSettings().subscribe(settings => {
      this.settings = settings;
      this.loading = false;
    });
  }

  addUser() {
    this.router.navigateByUrl('settings/user/add').then();
  }

  addedUsers(userDetails: UserDetails[]) {
    return userDetails.filter(user => !user.owner)
  }

  selectUser(selectedUser: UserDetails) {
    this.settings.userDetails.forEach(user => {
      if(selectedUser != user) {
        user.selected = false;
      } else {
        user.selected = !user.selected;
      }
    });
  }

  deleteUser(user: UserDetails) {
    this.loading = true;
    this.userService.deleteUser(user.id).subscribe(value => {
      this.getSettings();
    });
  }

  protected readonly environment = environment;

  paypal() {
    this.paymentService.pay("BASIC", "PAYPAL").subscribe(value => {
      window.location.href = value.redirectUrl;
    }, error => {
      console.log(error);
    });
  }
}
