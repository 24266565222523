import {Injectable} from '@angular/core';
import {CategoryService} from './category.service';
import {SubCategory} from './models/sub-category.model';
import {Category} from './models/category';

@Injectable({
  providedIn: 'root',
})
export class CategoryDataService {

  category: SubCategory[];
  mainCategories: Category[];

  constructor(private categoryService: CategoryService) {}

  getSubCategories(force: boolean): Promise<SubCategory[]> {
    return new Promise<SubCategory[]>((resolve) => {
      if (this.category && !force) {
        resolve(this.category);
      } else {
        this.categoryService.getSubCategories().subscribe((data) => {
          this.category = data as SubCategory[];
          resolve(this.category);
        });
      }
    });
  }

  getMainCategories(force: boolean): Promise<Category[]> {
    return new Promise<Category[]>((resolve) => {
      if (this.mainCategories && !force) {
        resolve(this.mainCategories);
      } else {
        this.categoryService.getMainCategories().subscribe((data) => {
          this.mainCategories = data as Category[];
          resolve(this.mainCategories);
        });
      }
    });
  }

  clear() {
    this.category = undefined;
    this.mainCategories = undefined;
  }
}
