<app-transaction-search *ngIf="init && (transactions.length > 0 || moreElements)" [searchFunction]="transactionSearchCallback" [transactionSearchModel]="transactionSearch"></app-transaction-search>


<div *ngIf="transactions.length === 0 && !moreElements" style="margin-top:40vh" class="container justify-content-center col-md-6">
  <div class="alert alert-info text-center" role="alert" [innerHtml]="'transactions.add-first-transaction' | translate"></div>
</div>

<div *ngFor="let day of transactions">

  <div class="transactions-list-day text-start">
    <span>{{day.date | date: 'longDate' : '' : this.translationService.getLanguage()}}</span>
  </div>

  <div class="transactions-list-item mt-1" [class.first]="transactionIndex == 0"
       [class.last]="transactionIndex == day.transactions.length-1"
       *ngFor="let transaction of day.transactions; let transactionIndex = index"
       (click)="details(transaction.transactionId)">
    <div class="row flex-nowrap">
      <div class="col-6">
        <span *ngIf="isOwnTransaction(transaction)">{{getSubCategoryName(transaction.subTransactions[0].categoryId)}}</span>
        <span *ngIf="!isOwnTransaction(transaction)">{{transaction.name}}</span>
      </div>

      <div class="col-6" [ngClass]="getTransactionTypeClass(transaction)">
        <div class="float-end">
            <span>
              {{getTransactionAmount(transaction)}}
            </span>
        </div>
      </div>

    </div>

    <div class="row flex-nowrap small">
      <div class="col-8">
        <div *ngIf="transaction.subTransactions.length === 1">
          <span *ngIf="!isOwnTransaction(transaction)">{{getSubCategoryName(transaction.subTransactions[0].categoryId)}}</span>
          <span *ngIf="isOwnTransaction(transaction)">{{getAccountName(transaction.subAccountFromId)}} -> {{getAccountName(transaction.subAccountToId)}}</span>
        </div>
      </div>

      <div class="col-4" [ngClass]="getTransactionTypeClass(transaction)">
        <div class="float-end">
              <span *ngIf="accountToDifferentCurrency(transaction)" bTooltip data-bs-placement="left" data-bs-toggle="tooltip" data-bs-html="true" [attr.data-bs-title]="getOwnTransactionCurrencyTooltip(transaction)">
                <span class="fa-solid fa-right-left small"></span>
                {{amountService.format(transaction.amountTo, getAccountCurrency(transaction.subAccountToId)) }}
              </span>
              <span *ngIf="transaction.amountInClientCurrency" bTooltip data-bs-placement="left" data-bs-toggle="tooltip" data-bs-html="true" [attr.data-bs-title]="getTransactionCurrencyTooltip(transaction, transaction.amount, transaction.amountInClientCurrency)">
                <span class="fa-solid fa-right-left small"></span>
                  {{ getTransactionClientAmount(transaction) }}
              </span>
        </div>
      </div>
    </div>

    <div class="mt-2" *ngIf="transaction.subTransactions.length > 1">
      <div *ngFor="let subTransaction of transaction.subTransactions">
        <div class="row small">
          <div class="col-6">
            <span>{{ getSubCategoryName(subTransaction.categoryId) }}</span>
          </div>

          <div class="col-6" [ngClass]="getSubTransactionTypeClass(subTransaction)">
            <span class="float-end">
              <span *ngIf="subTransaction.clientCurrencyAmount" bTooltip data-bs-placement="left" data-bs-toggle="tooltip" data-bs-html="true" [attr.data-bs-title]="getTransactionCurrencyTooltip(transaction, subTransaction.amount, subTransaction.clientCurrencyAmount)">
                <span *ngIf="subTransaction.clientCurrencyAmount" class="fa-solid fa-right-left small"></span>
                {{getSubTransactionAmount(transaction, subTransaction)}}
              </span>
              <span *ngIf="!subTransaction.clientCurrencyAmount">
                {{getSubTransactionAmount(transaction, subTransaction)}}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-action-when-visible *ngIf="init && moreElements" [action]="loadNextTransaction"></app-action-when-visible>

<div class="pt-5"></div>
<div class="pt-5"></div>

<div class="row h-100 mt-5 flex flex-column" *ngIf="!init">
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
    </div>
  </div>
</div>

<a (click)="newTransaction()" class="float">
  <i class="fa fa-plus my-float"></i>
</a>

