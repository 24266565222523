import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    let splitted: string[];
    splitted = value.split('-');
    return {
      year: Number.parseInt(splitted[2], 10),
      month: Number.parseInt(splitted[1], 10),
      day: Number.parseInt(splitted[0], 10)
    };
  }

  format(date: NgbDateStruct): string {
    if (date) {
    return '' + date.day + '-' + date.month + '-' + date.year;
    } else {
      return '';
    }
  }
}
