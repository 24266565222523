import {Component, OnInit} from '@angular/core';
import {TRANSACTION_TYPES} from '../../transaction-type/transaction-types.enum';
import {SubTransaction} from '../../models/sub-transaction';
import {Category} from '../../../category/models/category';
import {Transaction} from '../../models/transaction';
import {TransactionService} from '../../transaction.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {DataService} from "@shared/DataService";
import {AuthenticationService} from "../../../configuration/authentication.service";
import {NumberFormatService} from "@shared/services/number-format.service";
import {NgForm} from "@angular/forms";
import {Account} from "../../../account/models/account";
import {SubAccount} from "../../../account/models/sub-account";
import {Currency} from "../../../currency/models/Currency";

@Component({
  templateUrl: 'transaction.html'
})
export class TransactionComponent implements OnInit {
  TransactionTypes = TRANSACTION_TYPES;
  accounts: Array<Account> = [];
  categories: Array<Category> = [];
  model = new Transaction();
  initialAmount: number;
  sum: number;
  loaded = false;
  id: number;

  constructor(
    private transactionService: TransactionService,
    private router: Router,
    private dataService: DataService,
    private _location: Location,
    private userService: AuthenticationService,
    public amountService: NumberFormatService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAccounts().then(accounts => {
      this.accounts = accounts;
      this.getCategories().then(categories => {
        this.categories = categories;
        this.route.queryParams.subscribe((params: Params) => {
          this.id = params['id'];
          if (this.id) {
            this.getTransactionDetails().add(() => {
              this.loaded = true;
            });
          } else {
            this.model.subTransactions.push(new SubTransaction());
            this.loaded = true;
          }
        });
      });
    });
  }

  getTransactionDetails(): Subscription {
    return this.transactionService.getDetails(this.id).subscribe(data => {
      this.model = data as Transaction;
      this.model.date = new Date(this.model.date).toLocaleDateString('en-CA');
      this.model.subTransactionsToRemove = [];
      this.initialAmount = this.model.amount;
      this.sum = this.initialAmount;
      });
  }

  change(type: TRANSACTION_TYPES) {
    this.model.subAccountToId = null;
    this.model.amountTo = null;
    if(type === TRANSACTION_TYPES.OWN_TRANSFER) {
      this.model.subTransactions = [];
      this.model.subTransactions.push(new SubTransaction());
    }
    this.model.transactionType = type;
  }

  countTransactionSum(index: number): void {
    if (index === 0) {
      if(this.model.subTransactions[0].amount) {
        this.initialAmount = Number.parseFloat(this.model.subTransactions[0].amount.toFixed(2));
      } else {
        this.initialAmount = 0;
      }
    }

    if (this.initialAmount) {
      this.model.subTransactions[0].amount = this.initialAmount;
      for (let i = 1; i < this.model.subTransactions.length; i++) {
        this.model.subTransactions[i].amount = Number.parseFloat(this.model.subTransactions[i].amount.toFixed(2));
        this.model.subTransactions[0].amount -= this.model.subTransactions[i].amount;
      }
      this.model.subTransactions[0].amount = Number.parseFloat(this.model.subTransactions[0].amount.toFixed(2));
    }
    this.sum = this.getSubTransactionsSum();
  }

  transaction(transactionForm: NgForm): void {
    if(transactionForm.form.valid) {
      if (!this.model.transactionId) {
        this.transactionService.addTransaction(this.model).subscribe(() => {
          this.dataService.clearAccounts();
          this.router.navigateByUrl('/transactions').then();
        });
      } else {
        this.transactionService.editTransaction(this.model).subscribe(() => {
          this.router.navigateByUrl('/transactions').then();
        });
      }
    } else {+
      transactionForm.form.markAllAsTouched();
    }
  }

  getAccounts(): Promise<Account[]> {
    return this.dataService.getAccounts();
  }

  getCategories(): Promise<Category[]> {
    return this.dataService.getMainCategories();
  }

  addSubTransaction(): void {
    this.model.subTransactions.push(new SubTransaction());
  }

  removeSubTransaction(subTransaction: SubTransaction): void {
    this.model.subTransactions.splice(this.model.subTransactions.indexOf(subTransaction), 1);
    if(subTransaction.subTransactionId > 0) {
      this.model.subTransactionsToRemove.push(subTransaction.subTransactionId);
    }
    this.countTransactionSum(this.model.subTransactions.length);
  }

  getSubTransactionsSum(): number {
    return this.model.subTransactions.reduce((sum, subTransaction) => sum + subTransaction.amount, 0);
  }

  cancel(): void {
    this._location.back();
  }

  isDifferentCurrency(): boolean {
    if(this.model.transactionType === this.TransactionTypes.OWN_TRANSFER) {
      if(this.model.subAccountToId) {
        let account = this.findSubAccount(this.model.subAccountFromId);
        let accountTo = this.findSubAccount(this.model.subAccountToId);

        if(account.currencyId !== accountTo.currencyId) {
          return true;
        }
      }
    }
    return false;
  }

  ownTransferChange() {
    if(!this.isDifferentCurrency()) {
      this.model.subTransactions[0].amountTo = this.model.subTransactions[0].amount;
    }
  }

  getAccountCurrency(subAccountFromId: number): Currency {
    if(subAccountFromId) {
      let currencyId = this.findSubAccount(subAccountFromId).currencyId;
      return this.userService.getUser().currencies.find(value => value.id === currencyId);
    } else {
      return undefined;
    }
  }

  isExpense() {
    return this.model.transactionType === this.TransactionTypes.EXPENSE;
  }

  isIncome() {
    return this.model.transactionType === this.TransactionTypes.INCOME;
  }

  isOwnTransfer() {
    return this.model.transactionType === this.TransactionTypes.OWN_TRANSFER;
  }

  delete() {
    this.transactionService.remove(this.id).subscribe(() => {
      this.dataService.clearAccounts();
      this.router.navigateByUrl('/transactions').then();
    })
  }

  findSubAccount(subAccountId): SubAccount {
    for (let i = 0; i < this.accounts.length; i++) {
      const subAccount = this.accounts[i].subAccountList.find(value => value.id == subAccountId);
      if(!!subAccount) {
        return subAccount;
      }
    }
  }
}
