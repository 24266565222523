import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StatisticsFiltersModel} from './model/statistics-filters.model';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {

  constructor(private http: HttpClient) { }

  getTransactions(filters: StatisticsFiltersModel) {
    return this.http.post(environment.domain + '/api/statistics/transactions', JSON.parse(JSON.stringify(filters)));
  }
}
