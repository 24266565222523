import {Component, Input, OnInit} from '@angular/core';
import {AccountDataService} from '../../account/accountDataService';
import {SubAccount} from '../../account/models/sub-account';
import {TransactionSearchModel} from './transaction-search.model';

@Component({
  templateUrl: 'transaction-search.html',
  selector: 'app-transaction-search'
})
export class TransactionSearchComponent implements OnInit {

  @Input() transactionSearchModel: TransactionSearchModel;
  @Input() searchFunction: Function;
  accounts: SubAccount[] = [];

  constructor(private dataService: AccountDataService) {
  }

  ngOnInit(): void {
    this.dataService.getSubAccounts().then(value => {
      this.accounts = value;
    });
  }

  modelChange() {
    this.searchFunction();
  }
}
