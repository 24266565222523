import {Injectable} from "@angular/core";
import {ModalsModel} from "../../app/modal/modals.model";
import {LanguageService} from "@shared/services/language.service";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modals: ModalsModel[] = [];

  constructor(private translateService: LanguageService) {
  }

  open(modal: ModalsModel) {
    this.modals.push(modal);
    return modal;
  }

  close(modal: ModalsModel) {
    this.modals = this.modals.filter(obj => { return obj !== modal });
  }

  waitModal(body?: string) {
    const modal = new ModalsModel( {
      title: this.translateService.translate('modal.please-wait-title'),
      body: body ? this.translateService.translate(body) : this.translateService.translate('modal.saving-body'),
      canClose: false
    });
    modal.canClose = false;
    this.modals.push(modal);
    return modal;
  }

}
