import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {RouterModule, Routes} from '@angular/router';

import {TransactionsComponent} from './components/list/transactions.component';
import {TransactionSearchComponent} from './transactions-search/transaction-search.component';
import {TransactionComponent} from './components/details/transaction.component';
import {SharedModule} from '@shared/SharedModule';

const routes: Routes = [
    { path: 'transactions', component: TransactionsComponent },
    { path: 'transactions/new', component: TransactionComponent },
    { path: 'transactions/edit', component: TransactionComponent }
];

@NgModule({
    imports: [
      RouterModule.forChild(routes),
      FormsModule,
      SharedModule
    ],
    exports: [RouterModule],
    declarations: [
      TransactionsComponent,
      TransactionSearchComponent,
      TransactionComponent
    ],
})
export class TransactionModule { }
