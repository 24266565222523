import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {Account} from "./models/account";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class AccountService {

  headers = new HttpHeaders().append('Content-Type', 'application/json');

  constructor(private http: HttpClient) {}

  getAccounts() {
    return this.http.get(environment.domain + '/api/accounts/all');
  }

  editAccount(account: Account) {
    return this.http.post(environment.domain + '/api/accounts/', account);
  }

  getAccountDetails(id: number) {
    return this.http.get(environment.domain + '/api/accounts/details/' + id);
  }

}
