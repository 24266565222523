import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Category } from './models/category';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CategoryService {

  headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

  constructor(private http: HttpClient) { // dependency injection, creating an instance of HttpClient called http

  }

  addCategory(model: Category) {
    if (model.name) {
      return this.http.put(environment.domain + '/api/category/add',
        { name: model.name, color: model.color, subCategories: model.subCategories },
        { headers: this.headers });
    }
  }

  editCategory(model: Category) {
    if (model.name) {
      return this.http.post(environment.domain + '/api/category/edit',
        { name: model.name, color: model.color, subCategories: model.subCategories, id: model.id, toRemove: model.toRemove },
        { headers: this.headers });
    }
  }

  getSubCategories() {
    return this.http.get(environment.domain + '/api/category/subCategories');
  }

  getMainCategories() {
    return this.http.get(environment.domain + '/api/category/main');
  }

  getCategory(id: number) {
    return this.http.get(environment.domain + '/api/category/edit/' + id);
  }
}
