import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {AccountsComponent} from './components/list/accounts.component';
import {AccountComponent} from './components/details/account.component';
import {SharedModule} from "@shared/SharedModule";
import {accountRoute, accountsRoute} from "./accounts.route";

const routes: Routes = [
  {path: accountsRoute, component: AccountsComponent},
  {path: accountRoute, component: AccountComponent}
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    FormsModule
  ],
  exports: [RouterModule],
  declarations: [
    AccountComponent,
    AccountsComponent
  ],
})
export class AccountModule {}
