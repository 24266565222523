import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {AccountModule} from './account/account.module';
import {CategoryModule} from './category/category.module';
import {TransactionModule} from './transaction/transaction.module';
import {StatisticsModule} from './statistics/statistics.module';
import {LoginModule} from './login/login.module';
import {AuthenticationService} from './configuration/authentication.service';
import {RegisterModule} from './register/register.module';
import {SharedModule} from '@shared/SharedModule';
import {AuthInterceptor} from './configuration/AuthInterceptor';
import {AccountDataService} from './account/accountDataService';
import {DataService} from '@shared/DataService';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NavbarComponent} from "./navbar/navbar.component";
import {UserModule} from "./user/user.module";
import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import {environment} from "../environments/environment";
import {LanguageService} from "@shared/services/language.service";
import {CookieModule} from "ngx-cookie";
import {SettingsModule} from "./settings/settings.module";
import {NgOptimizedImage} from "@angular/common";
import {ModalsComponent} from "./modal/modals.component";
import {ModalComponent} from "./modal/modal.component";

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    secure: true
  },
  autoOpen: environment.showCookiesConsent,
  position: "bottom",
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'classic',
  type: 'info'
};

export function userServiceFactory(userService: AuthenticationService): Function {
  return () => userService.login(null);
}

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'transactions', pathMatch: 'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ModalComponent,
    ModalsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    CookieModule.withOptions(),
    AccountModule,
    TransactionModule,
    CategoryModule,
    StatisticsModule,
    LoginModule,
    RegisterModule,
    UserModule,
    SettingsModule,
    SharedModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage
  ],
  exports: [SharedModule],
  providers: [
    { provide: APP_INITIALIZER, useFactory: userServiceFactory, deps: [AuthenticationService], multi: true},
    { provide: LOCALE_ID, deps: [LanguageService], useFactory: (languageService) => languageService.getLanguage() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    AuthenticationService,
    DataService,
    AccountDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/app.', suffix: '.json'},
    { prefix: './assets/i18n/accounts/accounts.', suffix: '.json'},
    { prefix: './assets/i18n/login/login.', suffix: '.json'},
    { prefix: './assets/i18n/register/register.', suffix: '.json'},
    { prefix: './assets/i18n/user/user.', suffix: '.json'},
    { prefix: './assets/i18n/navbar/navbar.', suffix: '.json'},
    { prefix: './assets/i18n/category/category.', suffix: '.json'},
    { prefix: './assets/i18n/statistics/statistics.', suffix: '.json'},
    { prefix: './assets/i18n/transactions/transactions.', suffix: '.json'},
    { prefix: './assets/i18n/settings/settings.', suffix: '.json'}
  ]);
}
