import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LoginService} from './login.service';
import {AuthenticationService} from '../configuration/authentication.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";

export class JwtToken {
  accessToken: string;
  duration: number;
}

@Component({
  templateUrl: './login.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  success: string;
  error: string;

  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }

  get f() { return this.loginForm.controls; }

  constructor(
    private loginService: LoginService,
    private userService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) {

    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(false )
    });
  }

  ngOnInit() {

    this.success = undefined;
    this.error = undefined;

    this.route.queryParams.subscribe((params: Params) => {
      if(params['registration']) {
        this.success = "registration";
      }

      if(params['passwordReset']) {
        if(params['passwordReset'] === "success") {
          this.success = "resetSuccess";
        }
      }

      if(params['confirmation']) {
        if(params['confirmation'] === "success") {
          this.success = "confirmation";
        }

        if(params['confirmation'] === "exists") {
          this.error = "REGISTER_USER_EXISTS";
        }
      }

    });
  }

  logIn() {
    this.loginForm.markAllAsTouched();
    if(this.loginForm.valid) {
      this.loginService.logIn(this.loginForm).subscribe(
        (data: JwtToken) => {
          this.userService.login(data);
        },
        (error) => this.error = error.code,
        () => this.success = undefined
      );
    }
  }
}

