import {Component, Input, OnInit} from "@angular/core";
import {ModalsModel} from "./modals.model";
import {ModalService} from "@shared/services/modal.service";

@Component({
  templateUrl: "modal.template.html",
  selector: "budget-modal"
})
export class ModalComponent implements OnInit {

  @Input('modal') modal: ModalsModel;
  public title: string;
  public show: boolean;

  constructor(private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.title = this.modal.title;
    this.show = this.modal.visible;
  }

  close() {
    this.show = false;
    this.modal.promiseReject();
    this.modalService.modals = this.modalService.modals.filter(obj => { return obj !== this.modal });
  }

  confirm() {
    this.show = false;
    this.modal.promiseResolve(true);
    this.modalService.modals = this.modalService.modals.filter(obj => { return obj !== this.modal });
  }

}
