import {Component} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../user/user.service";
import {Router} from "@angular/router";

@Component({
  templateUrl: 'user.component.html'
})
export class UserComponent {
  userForm: FormGroup;

  get email() { return this.userForm.get('email'); }

  constructor(private router: Router, private userService: UserService) {

    this.userForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })

  }

  addUser() {
    this.userForm.markAllAsTouched();
    if(this.userForm.valid) {
      this.userService.addUser(this.userForm).subscribe(() => {
        this.back();
      });
    }

  }

  back() {
    this.router.navigateByUrl('/settings').then();
  }
}
