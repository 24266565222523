<div class="mt-3"></div>

<app-spinner-loader [text]="'categories.loading'" *ngIf="!init"></app-spinner-loader>

<div *ngIf="init && categories.length === 0" style="margin-top:40vh" class="container justify-content-center col-md-6">
  <div class="alert alert-info text-center" role="alert" [innerHtml]="'categories.add-first-category' | translate"></div>
</div>

<div class="accounts transactions-list-item mt-2" *ngFor="let category of categories"
     (click)="editCategory(category.id)">
  <div class="row">
    <span class="col-sm-6 col-lg-2 fw-bold">{{category.name}}</span>
  </div>
  <div *ngIf="category.subCategories.length > 1" class="mt-2">
    <div *ngFor="let subCategory of category.subCategories">
      <span class="small"> {{ subCategory.name }}</span>
    </div>
  </div>
</div>

<div class="pt-5"></div>
<div class="pt-5"></div>

<a (click)="addCategory()" class="float">
  <i class="fa fa-plus my-float"></i>
</a>
