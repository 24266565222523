import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {PasswordForgotComponent} from "./password-forgot/password-forgot.component";
import {SharedModule} from "@shared/SharedModule";
import {ComponentsModule} from "@shared/components/ComponentsModule";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {PasswordSetComponent} from "./password-set/password-set.component";


const routes: Routes = [
  { path: 'password-forgot', component: PasswordForgotComponent},
  { path: 'password-reset', component: PasswordResetComponent},
  { path: 'password-set', component: PasswordSetComponent}
]

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    PasswordForgotComponent,
    PasswordResetComponent,
    PasswordSetComponent
  ]
})

export class UserModule {}
