import { Injectable } from '@angular/core';
import {LocalStorageService} from "@shared/services/local-storage.service";
import {TranslateService} from "@ngx-translate/core";
import localePL from '@angular/common/locales/pl';
import {registerLocaleData} from "@angular/common";

registerLocaleData(localePL);


let POLISH = 'pl';
let ENGLISH = 'en';

export const LANGUAGE_KEY = "language";
export const DEFAULT_LANGUAGE = ENGLISH;

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private languages = [POLISH, ENGLISH];
  private readonly language: string;

  constructor(private localStorageService: LocalStorageService, private translateService: TranslateService) {
    this.translateService.setDefaultLang(POLISH);
    const userLang = navigator.language;
    this.language = this.languages.find(value => { return value === userLang}) ? userLang : DEFAULT_LANGUAGE;
    this.setLanguage();
  }

  private setLanguage(): void {
    this.translateService.use(this.localStorageService.getItem(LANGUAGE_KEY, this.localStorageService.getItem(LANGUAGE_KEY, this.language)));
  }

  getLanguage(): string {
    return this.localStorageService.getItem(LANGUAGE_KEY, DEFAULT_LANGUAGE);
  }

  getLanguages(): string[] {
    return this.languages;
  }

  change(language: string): void {
    this.localStorageService.setItem(LANGUAGE_KEY, language);
    this.setLanguage();
  }

  public translate(key): string {
    return this.translateService.instant(key);
  }

}
