import {SUB_ACCOUNT_TYPE} from "./sub-account-types.enum";

export class SubAccount {

  id: number;
  name: string;
  balance: number;
  currencyId: number;
  subAccountType: SUB_ACCOUNT_TYPE;
  clientCurrencyBalance: number;

  constructor() {
    this.balance = 0.00;
  }
}
