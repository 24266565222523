import {Component, OnInit} from '@angular/core';
import {TransactionService} from '../../transaction.service';
import {Transactions} from '../../models/transactions';
import {Router} from '@angular/router';
import {SubCategory} from '../../../category/models/sub-category.model';
import {SubAccount} from '../../../account/models/sub-account';
import {TransactionSearchModel} from '../../transactions-search/transaction-search.model';
import {TRANSACTION_TYPES} from '../../transaction-type/transaction-types.enum';
import {DataService} from '@shared/DataService';
import {AuthenticationService} from "../../../configuration/authentication.service";
import {Transaction} from "../../models/transaction";
import {NumberFormatService} from "@shared/services/number-format.service";
import {SubTransaction} from "../../models/sub-transaction";
import {TransactionList} from "../../models/transactionList";
import {LanguageService} from "@shared/services/language.service";
import {ModalService} from "@shared/services/modal.service";
import {ModalsModel} from "../../../modal/modals.model";
import {AccountsRoute} from "../../../account/accounts.route";
import {CategoriesRoute} from "../../../category/categories.route";

@Component({
  templateUrl: 'transactions.html'
})
export class TransactionsComponent implements OnInit {
  TransactionTypes = TRANSACTION_TYPES;
  loading = true;
  init = false;
  transactions: Transactions[] = [];
  subAccounts: SubAccount[] = [];
  categories: SubCategory[] = [];
  transactionSearch: TransactionSearchModel = new TransactionSearchModel();
  transactionSearchCallback: Function;
  loadNextTransaction: Function;
  loadingMore = true;
  moreElements = true;

  constructor(
    private transactionService: TransactionService,
    private router: Router,
    protected userService: AuthenticationService,
    private dataService: DataService,
    public amountService: NumberFormatService,
    public translationService: LanguageService,
    private modalService: ModalService,
    private accountRoute: AccountsRoute,
    private categoriesRoute: CategoriesRoute) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.dataService.getSubAccounts().then(subAccounts => {
      this.subAccounts = subAccounts;
      this.dataService.getSubCategories().then(categories => {
        this.categories = categories;
        this.getTransactions(false).add(() => {
          this.transactionSearchCallback = this.getTransactions.bind(this, false);
          this.loadNextTransaction = this.fetchTransactions.bind(this);
          this.init = true;
        });
      });
    });
  }

  getTransactions(nextPage) {
    this.loading = true;
    if (nextPage) {
      this.transactionSearch.page++;
    } else {
      this.transactionSearch.page = 0;
      this.transactions = [];
    }
    return this.transactionService.getTransactions(this.transactionSearch).subscribe(data => {
      const trans: TransactionList = data as TransactionList;
      this.moreElements = trans.more;
      this.transactions = this.transactions.concat(trans.transactions);
    }).add(() => {
      this.loading = false;
      this.loadingMore = false;
    });
  }

  details(id: number) {
    this.router.navigate(['transactions/edit'], {queryParams: {id: id}}).then();
  }

  getSubCategoryName(subcategoryId: number): string {
    if (subcategoryId) {
      return this.categories.find(value => value.id === subcategoryId).name;
    } else {
      return 'Przelew własny';
    }
  }

  getAccountName(accountId: number): string {
    return this.subAccounts.find(value => value.id === accountId).name;
  }

  fetchTransactions() {
    if (!this.loading) {
      this.getTransactions(true);
    }
  }

  getAccountCurrency(accountId: number): number {
    let id = this.subAccounts.find(value => value.id === accountId).currencyId;
    return this.userService.getUser().currencies.find(value => value.id === id).id;
  }

  accountToDifferentCurrency(transaction: Transaction) {
    if (transaction.transactionType === TRANSACTION_TYPES.OWN_TRANSFER) {
      return this.subAccounts.find(value => value.id === transaction.subAccountFromId).currencyId !== this.subAccounts.find(value => value.id === transaction.subAccountToId).currencyId;
    }
    return false;
  }

  getSubTransactionTypeClass(subTransaction: SubTransaction) {
    if (subTransaction.amount > 0) {
      return 'text-danger';
    } else {
      return 'text-success';
    }
  }

  getTransactionTypeClass(transaction: Transaction) {
    switch (transaction.transactionType) {
      case this.TransactionTypes.EXPENSE:
        return 'text-danger';
      case this.TransactionTypes.INCOME:
        return 'text-success';
      case this.TransactionTypes.OWN_TRANSFER:
        return 'text-secondary';
    }
  }

  getSubTransactionClientAmount(transaction: Transaction, amount: number, clientCurrencyAmount: number) {
    let amountValue;
    if(clientCurrencyAmount) {
      amountValue = clientCurrencyAmount;
    } else {
      amountValue = amount;
    }
    if (this.isExpense(transaction)) {
      amountValue = amountValue * -1;
    }
    return this.amountService.format(amountValue, this.userService.getUser().currencyId);
  }

  getSubTransactionAmount(transaction: Transaction, subTransaction: SubTransaction) {
    let amount = subTransaction.amount;
    if (transaction.transactionType === TRANSACTION_TYPES.EXPENSE) {
      amount = amount * -1;
    }
    return this.amountService.format(amount, this.getAccountCurrency(transaction.subAccountFromId));
  }

  getTransactionAmount(transaction: Transaction) {
    let amount = transaction.amount;
    if (this.isExpense(transaction)) {
      amount = amount * -1;
    }
    return this.amountService.format(amount, this.getAccountCurrency(transaction.subAccountFromId));
  }

  getTransactionClientAmount(transaction: Transaction) {
    let amount = transaction.amountInClientCurrency;
    if (transaction.transactionType === TRANSACTION_TYPES.EXPENSE) {
      amount = amount * -1;
    }
    return this.amountService.format(amount, this.userService.getUser().currencyId);
  }

  isOwnTransaction(transaction: Transaction) {
    return transaction.transactionType === TRANSACTION_TYPES.OWN_TRANSFER;
  }

  isExpense(transaction: Transaction) {
    return transaction.transactionType === TRANSACTION_TYPES.EXPENSE;
  }

  getOwnTransactionCurrencyTooltip(transaction: Transaction) {
    return '<div>' + this.getTransactionAmount(transaction) + '</div>' +
      '<div>' + this.amountService.format(1, this.getAccountCurrency(transaction.subAccountFromId)) + ' = '  + this.amountService.format(transaction.amountTo / transaction.amount, this.getAccountCurrency(transaction.subAccountToId)) + '</div>' +
      '<div>' + this.amountService.format(1, this.getAccountCurrency(transaction.subAccountToId)) + ' = '  + this.amountService.format(transaction.amount / transaction.amountTo, this.getAccountCurrency(transaction.subAccountFromId)) + '</div>';
  }

  getTransactionCurrencyTooltip(transaction: Transaction, amount: number, clientCurrencyAmount: number) {
    return '<div>' + this.getSubTransactionClientAmount(transaction, amount, clientCurrencyAmount) + '</div>' +
      '<div>' + this.amountService.format(1, this.getAccountCurrency(transaction.subAccountFromId))+ ' = '  + this.amountService.format(clientCurrencyAmount / amount, this.userService.getUser().currencyId) + '</div>' +
      '<div>' + this.amountService.format(1, this.userService.getUser().currencyId)+ ' = '  + this.amountService.format(amount / clientCurrencyAmount, this.getAccountCurrency(transaction.subAccountFromId)) + '</div>';
  }

  newTransaction() {

    const noAccounts = this.subAccounts.length == 0;
    const noCategories = this.categories.length == 0;

    const modal = this.getNoAccountNoCategoriesModal(noAccounts, noCategories);
      if(modal) {
      this.modalService.open(modal);

      modal.promise.then(() => {
        if(noAccounts) {
          this.accountRoute.newAccount();
        } else {
          this.categoriesRoute.newCategory();
        }
      }, reason => {

      });
    } else {
      this.router.navigateByUrl("transactions/new");
    }
  }

  getNoAccountNoCategoriesModal(noAccounts, noCategories): ModalsModel {
    if (noAccounts || noCategories) {
      let body = "";
      const title = this.translationService.translate('transactions.no-accounts-modal-title');
      let confirm;
      if (noAccounts) {
        body += this.translationService.translate('transactions.no-accounts');
        confirm = this.translationService.translate('transactions.add-account-modal-confirm');
        if (noCategories) {
          body += this.translationService.translate('transactions.no-accounts-no-categories');
        }
      } else {
        body += this.translationService.translate('transactions.no-categories');
        confirm = this.translationService.translate('transactions.add-category-modal-confirm');
      }

      return new ModalsModel( {
        title: title,
        body: body,
        confirm: confirm
      });
    } else {
      return undefined;
    }
  }

}
