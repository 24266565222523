import { NgModule } from '@angular/core';
import {InputErrorsComponent} from './input-errors/input-errors.component';
import {CommonModule} from '@angular/common';
import {ActionWhenVisibleComponent} from '@shared/components/action-when-visible/action-when-visible.component';
import {TranslateModule} from '@ngx-translate/core';
import {BootstrapValidationDirective} from '@shared/components/bootstrap-validation-directive/bootstrap-validation.directive';
import {TooltipDirective} from "@shared/components/bootstrap-tooltip/tooltip-directive";
import {LanguageComponent} from "@shared/components/language/language.component";
import {ThemeComponent} from "@shared/components/theme/theme.component";
import {FormsModule} from "@angular/forms";
import {ClickStopPropagation} from "@shared/components/click-stop-propagation/click-stop-propagation.directive";
import {SpinnerLoaderComponent} from "@shared/components/spinner-loader/spinner-loader.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    InputErrorsComponent,
    ActionWhenVisibleComponent,
    BootstrapValidationDirective,
    TooltipDirective,
    LanguageComponent,
    ThemeComponent,
    ClickStopPropagation,
    SpinnerLoaderComponent
  ],
  declarations: [
    InputErrorsComponent,
    ActionWhenVisibleComponent,
    BootstrapValidationDirective,
    TooltipDirective,
    LanguageComponent,
    ThemeComponent,
    ClickStopPropagation,
    SpinnerLoaderComponent
  ],
})
export class ComponentsModule { }
