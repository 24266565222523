<app-spinner-loader *ngIf="!init"></app-spinner-loader>

<div class="row mt-3">
  <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm)" *ngIf="init">
    <div class="row">
      <div class="col-8 col-md-9">
        <div class="form-group">
          <label for="name" [translate]="'category.group'"></label>
          <input [(ngModel)]="category.name" class="form-control" id="name" name="name" required type="text">
        </div>
      </div>
      <div class="col-2">
        <label for="color" [translate]="'category.color'"></label>
        <input [(colorPicker)]="category.color" [cpPosition]="'bottom-left'" [cpOKButton]="true" [cpCancelButton]="true" [style.background]="category.color"
               class="form-control color-pick" id="color" name="color" required readonly/>
      </div>
    </div>

    <div class="row" *ngFor="let subCategory of category.subCategories; index as i">
      <div class="col-8 col-md-9">
        <div class="form-group">
          <label for="name{{i}}" [translate]="'category.name'"></label>
          <input [(ngModel)]="subCategory.name" class="form-control" id="name{{i}}" name="name{{i}}" required type="text">
        </div>
      </div>
      <div class="col-2">
        <label for="color{{i}}" [translate]="'category.color'"></label>
        <input [(colorPicker)]="subCategory.color" [cpPosition]="'bottom-left'" [cpOKButton]="true" [cpCancelButton]="true" [style.background]="subCategory.color"
               class="form-control color-pick" id="color{{i}}" name="color{{i}}" required readonly/>
      </div>
      <div *ngIf="i >= 1" class="col-2 col-md-1 align-self-end form-group d-grid">
        <button type="button" class="btn btn-block btn-danger" (click)="removeSubCategory(subCategory)">x</button>
      </div>
      <div *ngIf="i == 0" class="col-2 col-md-1 align-self-end form-group d-grid">
        <button type="button" class="btn btn-block btn-success" (click)="addSubCategory()">+</button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="d-grid gap-2 d-flex justify-content-end">
        <button class="btn btn-warning" [routerLink]="'/categories'" [translate]="'category.back'"></button>
        <button class="btn btn-success" type="submit" [translate]="'category.save'"></button>
      </div>
    </div>
  </form>
</div>
