import {Component, OnInit} from "@angular/core";
import {UserService} from "../user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  templateUrl: "./password-forgot.html"
})
export class PasswordForgotComponent implements OnInit {
  passwordReminderForm: FormGroup;
  success: boolean;
  error: string;

  get username() { return this.passwordReminderForm.get('username'); }
  get f() { return this.passwordReminderForm.controls; }

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.success = false;
    this.error = undefined;
    this.passwordReminderForm = new FormGroup({
      username: new FormControl('', [ Validators.required, Validators.email])
    })
  }

  sendForm() {
    this.passwordReminderForm.markAllAsTouched();
    this.success = false;
    this.error = undefined;
    if(this.passwordReminderForm.valid) {
      this.userService.passwordForgot(this.username.value)
        .subscribe(
          () => {
            this.success = true;
          },
          (error) => {
            this.error = error.error;
          }
        );
    }
  }

}
