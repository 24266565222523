import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

export const accountsRoute = 'accounts';
export const accountRoute = 'account';

@Injectable({
  providedIn: 'root',
})
export class AccountsRoute {

  constructor(private router: Router) {
  }

  accounts() {
    this.router.navigate([accountsRoute]).then(() => null);
  }

  newAccount() {
    this.router.navigate([accountRoute]).then(() => null);
  }

  accountDetails(id: number) {
    this.router.navigate([accountRoute], {queryParams: {id: id}}).then(() => null);
  }

}
