import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-action-when-visible',
  template:
    `<div class="mt-3 mb-3" #element>
      <div *ngIf="showSpinner" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>`
})
export class ActionWhenVisibleComponent implements OnInit, OnDestroy {
  @ViewChild('element') element;
  @Input() period = 500;

  @Input() action: Function;
  interval: Subscription;
  showSpinner = false;
  eventFired: false;

  constructor() { }

  ngOnInit() {
    this.interval = interval(this.period).subscribe(() => {
      this.isVisible();
    });
  }

  ngOnDestroy(): void {
    this.interval.unsubscribe();
  }

  isVisible() {
    const rect = this.element.nativeElement.getBoundingClientRect();
    if (this.checkVisibility(rect) && !this.eventFired) {
      this.showSpinner = true;
      this.action();
    } else if (this.eventFired) {
      this.showSpinner = false;
      this.eventFired = false;
    }
  }

  checkVisibility(rect) {
    return rect.top >= 0 && rect.left >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth);
  }

}
