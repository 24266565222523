import {SubAccount} from "./sub-account";

export class Account {

  id: number;
  name: string;
  showSubAccounts: boolean;
  subAccountList: SubAccount[]
  balance: number;

  constructor() {
    this.showSubAccounts = false;
    this.subAccountList = [];
  }
}
