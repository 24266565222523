<div *ngIf="this.loaded">
  <form #transactionForm="ngForm">
    <div class="row mt-5" *ngIf="!this.id">
      <div class="col-4 text-center">
        <button type="button" class="btn btn-outline-danger btn-circle btn-xl" [ngClass]="{'active': isExpense()}"
                (click)="change(TransactionTypes.EXPENSE)">
          <i class="fa fa-arrow-up"></i>
        </button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-outline-success btn-circle btn-xl" [ngClass]="{'active': isIncome()}"
                (click)="change(TransactionTypes.INCOME)">
          <i class="fa fa-arrow-down"></i>
        </button>
      </div>
      <div class="col-4 text-center">
        <button type="button" class="btn btn-outline-info btn-circle btn-xl" [ngClass]="{'active': isOwnTransfer()}"
                (click)="change(TransactionTypes.OWN_TRANSFER)">
          <i class="fa fa-exchange"></i>
        </button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-6" *ngIf="!isOwnTransfer()">
        <label for="transactionName" [translate]="'transaction.name'"></label>
        <input type="text" id="transactionName" name="transactionName" class="form-control" [(ngModel)]="model.name" required>
      </div>

      <div class="col" [ngClass]="{'col-12': isOwnTransfer()}">
        <label for="transactionDate" [translate]="'transaction.date'"></label>
        <input type="date" id="transactionDate" name="transactionDate" class="form-control" [(ngModel)]="model.date" required>
      </div>

      <div class="col" [ngClass]="{'col-6': isOwnTransfer()}">
        <label for="transactionAccountFrom" [translate]="'transaction.accountFrom'"></label>
        <select id="transactionAccountFrom" class="form-select" [(ngModel)]="model.subAccountFromId" name="transactionAccountFrom" required>
          <option hidden *ngIf="!model.subAccountFromId" label=" "></option>

          <ng-container *ngFor="let account of accounts">
            <optgroup label="{{account.name}}" *ngIf="account.subAccountList.length">
              <option *ngFor="let subAccount of account.subAccountList"
                      [value]="subAccount.id">{{subAccount.name}}</option>
            </optgroup>
          </ng-container>
        </select>
      </div>

      <div class="col-6" *ngIf="isOwnTransfer()">
        <label for="transactionAmount" [translate]="'transaction.amount'"></label>
        <div class="input-group">
          <input type="number"
                 required
                 id="transactionAmount"
                 [(ngModel)]="model.subTransactions[0].amount"
                 (change)="ownTransferChange()"
                 class="form-control"
                 name="transactionAmount"
                 [disabled]="!model.subAccountFromId">
          <span class="input-group-text" *ngIf="model.subAccountFromId"
                [translate]="'currency.' + this.getAccountCurrency(model.subAccountFromId).code"></span>
        </div>
      </div>

    </div>

    <div class="mt-5" *ngIf="!isOwnTransfer()">

      <div class="" *ngFor="let subTransaction of model.subTransactions; index as i">
        <div class="row">
          <div class="col-6">
            <label for="transactionCategory{{i}}" [translate]="'transaction.category'"></label>
            <select id="transactionCategory{{i}}" class="selectpicker form-select" data-live-search="true"
                    [(ngModel)]="subTransaction.categoryId" required name="transactionCategory{{i}}">
              <option hidden *ngIf="!subTransaction.categoryId" label=" "></option>
              <ng-container *ngFor="let category of categories">
                <optgroup label="{{category.name}}" *ngIf="category.subCategories.length > 1">
                  <option *ngFor="let subCategory of category.subCategories"
                          [value]="subCategory.id">{{subCategory.name}}</option>
                </optgroup>
                <option *ngIf="category.subCategories.length <= 1"
                        [value]="category.subCategories[0].id">{{category.name}}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-4 col-lg-5">
            <label for="transactionAmount{{i}}" [translate]="'transaction.amount'"></label>
            <div class="input-group">
              <input type="number"
                     id="transactionAmount{{i}}"
                     [(ngModel)]="subTransaction.amount"
                     (change)="countTransactionSum(i)"
                     class="form-control"
                     name="transactionAmount{{i}}" required
                     [disabled]="(i === 0 && model.subTransactions.length > 1) || !model.subAccountFromId">
              <span class="input-group-text" *ngIf="model.subAccountFromId"
                    [translate]="'currency.' + this.getAccountCurrency(model.subAccountFromId).code"></span>
            </div>
          </div>
          <div *ngIf="i >= 1" class="col-2 col-lg-1 align-self-end d-grid">
            <button type="button" class="btn btn-block btn-danger" (click)="removeSubTransaction(subTransaction)">x
            </button>
          </div>
          <div *ngIf="i == 0" class="col-2 col-lg-1 align-self-end d-grid">
            <button
              type="button"
              class="btn btn-block btn-success"
              [disabled]="!subTransaction.amount"
              (click)="addSubTransaction()">+
            </button>
          </div>
        </div>

        <div class="row mt-2" *ngIf="!isOwnTransfer()">
          <div class="col-12 col-md-5 col-lg-4">
            <div class="form-switch form-check">
              <input id="statistics-{{i}}" type="checkbox" class="form-check-input"
                     [(ngModel)]="subTransaction.excludeStatistics" name="statistics-{{i}}">
              <label class="form-check-label ml-2" for="statistics-{{i}}"
                     [translate]="'transaction.excludeStatistics'"></label>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <div class="form-switch form-check">
              <input id="statistics2-{{i}}" type="checkbox" class="form-check-input" name="statistics2-{{i}}"
                     [(ngModel)]="subTransaction.constantExpense">
              <label class="form-check-label ml-2" for="statistics2-{{i}}"
                     [translate]="'transaction.constantExpense'"></label>
            </div>
          </div>
        </div>

      </div>

      <div class="row mt-1" *ngIf="model.subTransactions.length > 1">
        <div class="col-12 text-end fs-5">
          <span>{{'transaction.total' | translate}} {{amountService.format(sum, getAccountCurrency(model.subAccountFromId).id)}}</span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isOwnTransfer()">
      <div class="col-6">
        <label for="transactionAccountTo" [translate]="'transaction.accountTo'"></label>
        <select id="transactionAccountTo" class="form-select" [(ngModel)]="model.subAccountToId" name="transactionAccountTo" required>
          <option hidden *ngIf="!model.subAccountToId" label=" "></option>
          <ng-container *ngFor="let account of accounts">
            <optgroup label="{{account.name}}" *ngIf="account.subAccountList.length">
              <option *ngFor="let subAccount of account.subAccountList"
                      [value]="subAccount.id">{{subAccount.name}}</option>
            </optgroup>
          </ng-container>
        </select>
      </div>

      <div class="col-6" *ngIf="isOwnTransfer() && isDifferentCurrency()">
        <label for="transactionAmountTo" [translate]="'transaction.amountTo'"></label>
        <div class="input-group">
          <input type="number"
                 required
                 id="transactionAmountTo"
                 [(ngModel)]="model.subTransactions[0].amountTo"
                 class="form-control"
                 name="transactionAmountTo"
                 [disabled]="!model.subAccountToId">
          <span class="input-group-text" *ngIf="model.subAccountToId"
                [translate]="'currency.' + this.getAccountCurrency(model.subAccountToId).code"></span>
        </div>
      </div>

    </div>

    <div class="row mt-3">
      <div class="d-grid gap-2 d-flex justify-content-end">
        <button *ngIf="id" type="button" class="btn btn-danger" (click)="delete()" [translate]="'transaction.delete'"></button>
        <button type="button" class="btn btn-warning" (click)="cancel()" [translate]="'transaction.cancel'"></button>
        <button type="submit" class="btn btn-primary" (click)="transaction(transactionForm)" [translate]="id ? 'transaction.save' : 'transaction.submit'"></button>
      </div>
    </div>
  </form>
</div>

