export class TransactionSearchModel {

  accountId: number;
  page: number;

  constructor() {
    this.page = 0;
  }

}
