import {SubCategory} from './sub-category.model';

export class Category {

  name: string;
  id: number;
  color: string;
  main: boolean;
  toRemove: number[];
  subCategories: SubCategory[];

  constructor() {
    this.subCategories = [];
    this.toRemove = [];
    this.color = '#e66465';
  }
}
