import {Component} from '@angular/core';
import {CategoryService} from '../../category.service';
import {Category} from '../../models/category';
import {CategoriesRoute} from "../../categories.route";

@Component({
  templateUrl: './categories.html'
})
export class CategoriesComponent {
  categories: Category[] = [];
  init: boolean = false;

  constructor(
    private categoryService: CategoryService,
    private categoriesRoute: CategoriesRoute
  ) { }

  ngOnInit(): void { // adding the lifecycle hook ngOnInit
    this.init = false;
    this.getCategories();
  }

  getCategories() {
    this.categoryService.getMainCategories().subscribe(data => {
      this.categories = data as Category[];
      this.init = true;
    });
  }

  editCategory(id: number) {
    this.categoriesRoute.categoryDetails(id);
  }

  addCategory() {
    this.categoriesRoute.newCategory();
  }
}
