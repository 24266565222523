import {Injectable} from '@angular/core';
import {formatNumber} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "@shared/services/language.service";
import {AuthenticationService} from "../../app/configuration/authentication.service";
import {Currency} from "../../app/currency/models/Currency";

@Injectable({
  providedIn: 'root',
})
export class NumberFormatService {
  private readonly translateService: TranslateService;
  private readonly languageService: LanguageService;
  private readonly userService: AuthenticationService;

  constructor(translateService: TranslateService, languageService: LanguageService, userService: AuthenticationService) {
    this.translateService = translateService;
    this.languageService = languageService;
    this.userService = userService;
  }

  public format(amount: number, currencyId: number): string {
    let currency: Currency = this.userService.getUser().currencies.find(value => value.id === currencyId);
    return formatNumber(amount, this.languageService.getLanguage(), '1.2-' + currency.precision) + " " + this.translateService.instant('currency.' + currency.code);
  }
}
