import { Injectable } from '@angular/core';
import {LocalStorageService} from "@shared/services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private THEME_KEY = "theme";
  private THEME_ATTRIBUTE = "data-bs-theme";
  private DEFAULT_THEME = "dark";
  private DARK_THEME = 'dark';
  private LIGHT_THEME = 'light';

  constructor(private localStorageService: LocalStorageService) {
    this.setTheme();
  }

  private setTheme() {
    document.body.setAttribute(this.THEME_ATTRIBUTE, this.localStorageService.getItem(this.THEME_KEY, this.DEFAULT_THEME));
  }

  change(dark: boolean) {
    if(dark) {
      this.localStorageService.setItem(this.THEME_KEY, this.DARK_THEME);
    } else {
      this.localStorageService.setItem(this.THEME_KEY, this.LIGHT_THEME);
    }
    this.setTheme();
  }

  isDark() {
    return this.localStorageService.getItem(this.THEME_KEY, this.DEFAULT_THEME) === this.DARK_THEME;
  }

}
