<div class="mt-3">

  <form class="container" [formGroup]="userForm" (ngSubmit)="addUser()">

    <div class="col-12">
      <div class="form-group">
        <label for="email" class="form-label" [translate]="'settings.user.email'"></label>
        <input id="email" name="currency" type="email" class="form-control" required formControlName="email">
        <app-input-errors [control]="email" [prefix]="'settings.email'"></app-input-errors>
      </div>
    </div>

    <div class="row mt-3">
      <div class="d-grid gap-2 d-flex justify-content-end">
        <button class="btn btn-outline-warning" type="button" [translate]="'settings.user.back'" (click)="back()"></button>
        <button class="btn btn-outline-success" type="submit" [translate]="'settings.user.add'"></button>
      </div>
    </div>

  </form>

</div>
