<div class="d-flex align-items-center">

  <div class="container col-md-8 col-lg-6 col-xl-5 mb-5 mt-5">

    <h1 class="text-center fw-bold" [translate]="'password-forgot.title'"></h1>

    <hr class="divider"/>

    <form class="container" [formGroup]="passwordReminderForm" (ngSubmit)="sendForm()">

      <div class="alert alert-info text-center" role="alert" *ngIf="!success" [translate]="'password-forgot.info'"></div>
      <div class="alert alert-success" role="alert" *ngIf="success" [translate]="'password-forgot.success'"></div>
      <div class="alert alert-danger" role="alert" *ngIf="error" [translate]="'password-forgot.' + error"></div>

      <div class="form-group mt-5">
        <label for="username" class="form-label" [translate]="'password-forgot.username-label'"></label>
        <input aria-describedby="username" class="form-control" id="username" type="email" formControlName="username">
        <app-input-errors [control]="username" [prefix]="'password-forgot.username'"></app-input-errors>
      </div>

      <hr class="divider"/>

      <div class="row mt-3 justify-content-center">
        <div class="d-grid col-6">
          <button type="submit" class="btn btn-primary btn-block" [translate]="'password-forgot.submit'"></button>
        </div>
      </div>

    </form>

  </div>

</div>
