import {Component} from '@angular/core';
import { AccountService } from '../../account.service';
import { ActivatedRoute, Params} from '@angular/router';
import {AuthenticationService} from "../../../configuration/authentication.service";
import {Account} from "../../models/account";
import {SubAccount} from "../../models/sub-account";
import {SUB_ACCOUNT_TYPE} from "../../models/sub-account-types.enum";
import {DataService} from "@shared/DataService";
import {AccountsRoute} from "../../accounts.route";
import {ModalService} from "@shared/services/modal.service";

@Component({
  templateUrl: './account.html'
})
export class AccountComponent {
  model: Account;
  id: number;
  init: boolean;
  subAccountTypes: SUB_ACCOUNT_TYPE[];

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private dataService: DataService,
    private userService: AuthenticationService,
    private accountsRoute: AccountsRoute,
    private modalService: ModalService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.id = params['id'];
      this.subAccountTypes = this.userService.getUser().subAccountTypes;
      if(this.id) {
        this.accountService.getAccountDetails(this.id).subscribe( data => {
          this.model = data as Account;
          this.init = true;
        });
      } else {
        this.model = new Account();
        this.model.subAccountList.push(new SubAccount());
        this.init = true;
      }
    });
  }

  save() {
    const modal = this.modalService.waitModal();
    this.accountService.editAccount(this.model).subscribe(() => {
        this.accountsRoute.accounts();
    }).add(() => {
      this.modalService.close(modal);
    });
  }

  addSubAccount() {
    this.model.subAccountList.push(new SubAccount());
  }

  getCurrencies(subAccount: SubAccount) {
    return this.userService.getCurrenciesForAccountType(subAccount.subAccountType);
  }

  back() {
    history.back();
  }

  removeSubAccount(subAccount) {
    this.model.subAccountList = this.model.subAccountList.filter(value => {
      return subAccount !== value;
    })
  }
}
