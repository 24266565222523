import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import {User} from "./User";
import {SUB_ACCOUNT_TYPE} from "../account/models/sub-account-types.enum";
import {Currency} from "../currency/models/Currency";
import {CURRENCY_TYPE} from "../currency/models/CurrencyType";
import {DataService} from "@shared/DataService";
import {CookieService} from "ngx-cookie";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {JwtToken} from "../login/login.component";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private JWT_TOKEN_COOKIE_NAME = "JWT_TOKEN";

  private authenticated: boolean;
  private user: User;

  constructor(
    private loginService: LoginService,
    private dataService: DataService,
    private location: Location,
    private cookieService: CookieService,
    private router: Router
  ) {}

  login(token: JwtToken) {

    if(token) {
      const date = new Date();
      date.setTime(date.getTime() + (token.duration * 1000));

      this.cookieService.put(this.JWT_TOKEN_COOKIE_NAME, token.accessToken, {expires: date.toUTCString(), path: "/", sameSite: "strict"});
    }

    return new Promise<void>(resolve => {
      return this.loginService.getAccount().subscribe( (data) => this.onSuccess(data, resolve), () => this.onFailure(resolve));
    });
  }

  isAuthenticated() {
    return this.authenticated;
  }

  onSuccess(data, resolve) {
    this.authenticated = true;
    this.user = data;
    this.redirectOnGetAccount().then(value => {
      resolve(this.user);
    })
  }

  onFailure(resolve) {
    this.authenticated = false;
    this.user = undefined;
    resolve(null);
  }

  userCurrencyId(): number {
    return this.user.currencyId;
  }

  getUser(): User {
    return this.user;
  }

  getCurrenciesForAccountType(subAccountType: SUB_ACCOUNT_TYPE): Currency[] {
    let currencies: Currency[] = [];
    this.user.currencies.forEach(currency => {
      switch (subAccountType) {
        case SUB_ACCOUNT_TYPE.ACCOUNT:
        case SUB_ACCOUNT_TYPE.CASH:
        case SUB_ACCOUNT_TYPE.CREDIT_CARD:
        case SUB_ACCOUNT_TYPE.DEBIT_CARD:
          if(currency.type === CURRENCY_TYPE.CASH) {
            currencies.push(currency);
          }
          break;
        case SUB_ACCOUNT_TYPE.CRYPTO_CURRENCY:
          if(currency.type === CURRENCY_TYPE.CRYPTO) {
            currencies.push(currency);
          }
          break;
        case SUB_ACCOUNT_TYPE.STOCK:
          if(currency.type === CURRENCY_TYPE.STOCK) {
            currencies.push(currency);
          }
          break;
      }
    });
    return currencies;
  }

  logout() {
    this.cookieService.remove(this.JWT_TOKEN_COOKIE_NAME, {sameSite: "strict"});
    this.authenticated = false;
    this.user = undefined;
    this.dataService.clear();
  }

  forbidden() {
    this.logout();
    this.redirectOn401();
  }

  getToken() {
    return this.cookieService.get(this.JWT_TOKEN_COOKIE_NAME);
  }

  redirectOn401() {
    if(!this.location.path().startsWith("/login") &&
      !this.location.path().startsWith("/register") &&
      !this.location.path().startsWith("/password-set") &&
      !this.location.path().startsWith("/password-reset")) {
      this.router.navigateByUrl('login').then();
    }
  }

  redirectOnGetAccount() {
    if(this.location.path() === "/" ||
      this.location.path() === "" ||
      this.location.path().startsWith("/login") ||
      this.location.path().startsWith("/register") ||
      this.location.path().startsWith("/password-set") ||
      this.location.path().startsWith("/password-reset")) {
      return this.router.navigateByUrl('transactions');
    } else {
      return Promise.resolve(true);
    }
  }

}
