<div class="modal fade" tabindex="-1" style="display: block" [ngClass]="{'show' : show}" aria-modal="true" [attr.data-bs-backdrop]="modal.canClose ? null : 'static'">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" *ngIf="modal.canClose" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <p>{{modal.body}}</p>
      </div>
      <div class="modal-footer" *ngIf="modal.canClose">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="confirm()">{{modal.confirm}}</button>
      </div>
    </div>
  </div>
</div>
