import {Component, OnInit} from '@angular/core';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateFRParserFormatter} from './NgbDateFRParserFormatter';
import {TranslateService} from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

import 'cordova-plugin-purchase';

import Platform = CdvPurchase.Platform;
import ProductType = CdvPurchase.ProductType;
import LogLevel = CdvPurchase.LogLevel;
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}]
})
export class AppComponent implements OnInit{

  constructor(
    private translate: TranslateService,
    private ccService: NgcCookieConsentService
  ) {
    this.translate.setDefaultLang('en');


    if(environment.isAndroid) {

      CdvPurchase.store.verbosity = LogLevel.DEBUG;

      CdvPurchase.store.register([{
        id: 'month2',
        type: ProductType.PAID_SUBSCRIPTION,
        platform: Platform.GOOGLE_PLAY
      }]);

      CdvPurchase.store.initialize().then(() => {
        console.log('Store is ready!');
      });
    }

  }

  ngOnInit() {
    this.translate//
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy', 'cookie.url'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.href = data['cookie.url'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

}
