<app-spinner-loader *ngIf="!init"></app-spinner-loader>

<div class="container mt-2" *ngIf="init">
  <form #accountForm="ngForm">

    <div class="row mb-3">
      <div class="col-12">
        <div class="form-group">
          <label for="accountName" [translate]="'account.name'"></label>
          <input [(ngModel)]="model.name" class="form-control" id="accountName" name="accountName" required type="text">
        </div>
      </div>
    </div>

    <div class="mt-4 sub-account-details transactions-list-item" *ngFor="let subAccount of model.subAccountList; index as i">

      <div class="row">

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="subAccountName{{i}}" [translate]="'account.subAccountName'"></label>
            <input [(ngModel)]="subAccount.name" class="form-control" id="subAccountName{{i}}"
                   name="subAccountName{{i}}" required type="text">
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="subAccountType{{i}}" [translate]="'account.subAccountType'"></label>
            <select id="subAccountType{{i}}" name="subAccountType{{i}}" class="form-select" required="true"
                    [disabled]="subAccount.id != null && subAccount.subAccountType != null"
                    [(ngModel)]="subAccount.subAccountType">
              <option *ngFor="let subAccountType of subAccountTypes"
                      [ngValue]="subAccountType" [translate]="'account.type.' + subAccountType"></option>
            </select>
          </div>
        </div>

        <div class="col-8">
          <div class="form-group">
            <label for="subAccountBalance{{i}}" [translate]="'account.balance'"></label>
            <input id="subAccountBalance{{i}}" name="subAccountBalance{{i}}"
                   [(ngModel)]="subAccount.balance" class="form-control"
                   required
                   type="number">
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label for="subAccountCurrency{{i}}" [translate]="'account.currency'"></label>
            <select id="subAccountCurrency{{i}}" name="subAccountCurrency{{i}}" class="form-select" required
                    [disabled]="subAccount.id != null || subAccount.subAccountType == null"
                    [(ngModel)]="subAccount.currencyId">
              <option hidden label=" " *ngIf="!subAccount.currencyId"></option>
              <option *ngFor="let currency of getCurrencies(subAccount)"
                      [ngValue]="currency.id" [translate]="'currency.' + currency.code"></option>
            </select>
          </div>
        </div>
      </div>

      <div class="d-grid gap-2 d-flex justify-content-end mt-3" *ngIf="i !== 0 && !subAccount.id">
        <button class="btn btn-danger" (click)="removeSubAccount(subAccount)" [translate]="'account.removeSubAccount'"></button>
      </div>

    </div>

    <div class="d-grid gap-2 d-flex justify-content-end mt-3">
      <button class="btn btn-secondary" (click)="addSubAccount()" [translate]="'account.addSubAccount'"></button>
    </div>

    <div class="row mt-4">
      <div class="d-grid gap-2 d-flex justify-content-end">
        <button class="btn btn-warning" (click)="back()" [translate]="'account.back'"></button>
        <button class="btn btn-success" (click)="save()" [disabled]="!accountForm.form.valid"
                [translate]="'account.save'"></button>
      </div>
    </div>
  </form>
</div>

<div class="mt-5"></div>
<div class="mt-5"></div>
