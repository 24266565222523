<div class="mt-3" *ngIf="init">
    <div class="row">
      <div class="col-6">
        <label for="transactionAccount" [translate]="'statistics.account'"></label>
        <select id="transactionAccount" class="form-select" [(ngModel)]="filters.subAccountId" (ngModelChange)="getCategories()">
          <option [ngValue]="undefined" [translate]="'statistics.allAccounts'"></option>
          <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
        </select>
      </div>

      <div class="col-6">
        <label for="transactionCategory" [translate]="'statistics.category'"></label>
        <select id="transactionCategory" class="form-select" [(ngModel)]="filters.categoryId" (ngModelChange)="getCategories()">
          <option selected [ngValue]="undefined" [translate]="'statistics.allCategories'"></option>
          <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
        </select>
      </div>

    </div>

    <div class="row">
      <div class="col-6 col-md-3">
          <label for="transactionDateFrom" [translate]="'statistics.dateFrom'"></label>
          <input type="date" id="transactionDateFrom" class="form-control" [(ngModel)]="filters.dateFromNg" (ngModelChange)="dateFromChange()">
      </div>
      <div class="col-6 col-md-3">
        <label for="transactionDateTo" [translate]="'statistics.dateTo'"></label>
        <input type="date" id="transactionDateTo" class="form-control" [(ngModel)]="filters.dateToNg" (ngModelChange)="dateToChange()">
      </div>

    </div>

  <div class="row mt-2">
      <div class="col-6 col-md-3 d-grid">
        <button type="button" class="btn btn-secondary" (click)="changeMonth(-1)" [translate]="'statistics.previousMonth'"></button>
      </div>
      <div class="col-6 col-md-3 d-grid">
        <button type="button" class="btn btn-secondary" (click)="changeMonth(1)" [translate]="'statistics.nextMonth'"></button>
      </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 col-sm-6">
      <div class="form-group">
          <button type="button" class="btn btn-secondary btn-block" (click)="clearFilters()" [translate]="'statistics.clearFilters'"></button>
        </div>
    </div>
  </div>



  <div class="row h-100 mt-5 flex flex-column" *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div class="row mt-3 text-center" *ngIf="!loading">
    <div class="col-4">
      <h3><span [translate]="'statistics.incomes'"></span> <br> <span class="badge bg-success">{{amountService.format(income, userService.getUser().currencyId)}}</span></h3>
    </div>
    <div class="col-4">
      <h3><span [translate]="'statistics.expenses'"></span> <br> <span class="badge bg-danger">{{amountService.format(outcome, userService.getUser().currencyId)}}</span></h3>
    </div>
    <div class="col-4">
      <h3><span [translate]="'statistics.total'"></span> <br> <span class="badge bg-info">{{amountService.format(sum, userService.getUser().currencyId)}}</span></h3>
    </div>
  </div>

  <div class="row mt-4" *ngIf="!loading && barChartData.labels.length > 0">
    <div class="col-lg-6 col-md-12">
        <canvas baseChart style="{{getChartStyle()}}"
                [data]="barChartData"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [type]="'bar'">
        </canvas>
    </div>
    <div class="col-lg-6 col-md-12" style="max-height: 75vh; overflow: auto">

          <div class="transactions-list-item mt-1" *ngFor="let subTransaction of transactionsFiltered; index as i"
               (click)="details(subTransaction.transactionId)">
            <div class="row flex-nowrap">
              <div class="col-6">
                <span>{{subTransaction.transactionDate | date}}</span>
              </div>

              <div class="col-6" [ngClass]="getTransactionTypeClass(subTransaction.transactionType)">
                <div class="float-end">
                  <span>
                    {{getTransactionAmount(subTransaction.clientCurrencyAmount, subTransaction.transactionType)}}
                  </span>
                </div>
              </div>

            </div>

            <div class="row flex-nowrap small">
              <div class="col-8">
                <div>
                  <span>{{getSubCategoryNameById(subTransaction.categoryId)}}</span>
                </div>
              </div>
              <div class="col-4" *ngIf="subTransaction.clientCurrencyAmount !== subTransaction.amount">
                <div class="float-end" [ngClass]="getTransactionTypeClass(subTransaction.transactionType)">
                  <span *ngIf="subTransaction.clientCurrencyAmount" bTooltip data-bs-placement="left" data-bs-toggle="tooltip" data-bs-html="true" [attr.data-bs-title]="getTransactionCurrencyTooltip(subTransaction, subTransaction.amount, subTransaction.clientCurrencyAmount)">
                  <span *ngIf="subTransaction.clientCurrencyAmount" class="fa-solid fa-right-left small"></span>
                    {{getSubTransactionAmount(subTransaction)}}
                  </span>
                  <span *ngIf="!subTransaction.clientCurrencyAmount">
                    {{getSubTransactionAmount(subTransaction)}}
                  </span>
                </div>
              </div>
            </div>

          </div>
    </div>
  </div>

  <div class="pt-5"></div>

</div>
